import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

const Polyline = styled.polyline`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Fullscreen: React.FC<Props> = () => {
  return (
    <g>
      <Polyline points="11.5 28 11.5 38.5 22 38.5" />
      <Polyline points="22 11.5 11.5 11.5 11.5 22" />
      <Polyline points="38.5 22 38.5 11.5 28 11.5" />
      <Polyline points="28 38.5 38.5 38.5 38.5 28" />
      <Line x1="29.9" y1="29.9" x2="38.5" y2="38.5" />
      <Line x1="11.5" y1="11.5" x2="20.1" y2="20.1" />
      <Line x1="20.1" y1="29.9" x2="11.5" y2="38.5" />
      <Line x1="38.5" y1="11.5" x2="29.9" y2="20.1" />
    </g>
  );
};
