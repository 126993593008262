import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useAuth from 'hooks/useAuth';

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isAdminRoute?: boolean;
  authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthenticated,
  isAdminRoute,
  authenticationPath,
  ...rest 
}) => {
  const { isAdmin, loadingAuthState } = useAuth();

  if (loadingAuthState) {
    
    return null;
  }

  let redirectPath = '';
  if (!isAuthenticated) {
    
    redirectPath = authenticationPath;
  } else if (isAdminRoute && !isAdmin) {
    
    redirectPath = '/';
  }

  if (redirectPath) {
    
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...rest} component={renderComponent} render={undefined} />;
  } else {
    
    return <Route {...rest} />;
  }
};

export default ProtectedRoute;
