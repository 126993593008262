import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer, { reducerState } from './rootReducer';

export default () => {
  let history = createBrowserHistory();

  let persistedReducer = persistReducer({ key: 'root', storage, blacklist: ['router'] }, rootReducer(history));

  let store: any = configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      routerMiddleware(history),
    ],
  });

  let persistor = persistStore(store);

  return { store, persistor, history };
};

export type AppState = ReturnType<typeof reducerState>;
export type AppDispatch = typeof dispatchEvent;
