import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
  tooltip: string;
  tooltipToggle: boolean;
};

const initialState: state = {
  tooltip: '',
  tooltipToggle: false,
};

const slice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    setTooltip(state, action: PayloadAction<string>) {
      state.tooltip = action.payload;
    },
    toggleTooltip(state) {
      state.tooltipToggle = !state.tooltipToggle;
    },
  },
});

export const { setTooltip, toggleTooltip } = slice.actions;

export default slice.reducer;
