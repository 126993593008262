import { useFirestore } from 'react-redux-firebase';
import uniqid from 'uniqid';
import moment from 'moment';
import { firestoreCollection, docType, durationType } from 'util/constants';
import { deleteModal } from 'util/modal';

const useRecords = () => {
  const firestore = useFirestore();

  const addRecord = (currentProjectId: string, title?: string) => {
    const id = `record-${uniqid()}`;
    const newRecord: IRecord = {
      id: id,
      projectID: currentProjectId,
      date: moment().unix(),
      title: title ?? '',
      keyNumber: '',
      docType: docType.record,
      duration: 15,
      durationType: durationType.sec,
      links: [],
    };
    firestore.collection(firestoreCollection.records).doc(id).set(newRecord);
    return id;
  };

  const removeRecord = async (id: string) => {
    const confirm = await deleteModal(
      'Are you sure you want to delete this export file and all associated files? This is irreversible',
    );
    if (confirm) {
      firestore.collection(firestoreCollection.records).doc(id).delete();
    }
  };

  const updateRecord = (id: string, record: Partial<IRecord>) => {
    firestore.collection(firestoreCollection.records).doc(id).update(record);
  };

  return {
    addRecord,
    removeRecord,
    updateRecord,
  };
};

export default useRecords;
