import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Text, Box } from 'rebass/styled-components';
import { DropdownItemsType } from 'util/types';

const ItemContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
`;

const Item = styled.div<{ disabled?: boolean }>`
  height: 30px;
  background-color: ${(p) => (p.disabled ? 'rgb(200, 200, 200)' : '')};
  :nth-child(odd) {
    background-color: rgb(150, 150, 150);
  }
  :nth-child(even) {
    background-color: rgb(170, 170, 170);
  }
  :hover {
    background-color: ${(p) => !p.disabled && p.theme.colors.primary};
  }
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

const ItemText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

type Props = {
  menuItems: DropdownItemsType[];
  onSelect(item: DropdownItemsType): void;
  close(): void;
  containerRef: HTMLDivElement;
};

export const DropItems: React.FC<Props> = ({ menuItems, onSelect, close, containerRef }) => {
  const ref = useRef(null);

  const itemClick = (item: DropdownItemsType) => {
    if (!item.disabled) {
      onSelect(item);
    }
  };

  useEffect(() => {
    const handleClick = (event: any) => {
      if ((ref.current! as HTMLDivElement).contains(event.target) || containerRef.contains(event.target)) {
      } else {
        close();
      }
    };
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  return (
    <ItemContainer ref={ref}>
      {menuItems.map((item, index) => (
        <Item key={`${item.label}${index}`} disabled={item.disabled} onClick={() => itemClick(item)}>
          <ItemText>{item.label}</ItemText>
        </Item>
      ))}
    </ItemContainer>
  );
};
