import React from 'react';
import styled from 'styled-components';
import Theme from 'util/theme';
import { Icon } from './icon';
import useProjects from 'hooks/useProjects';

const ProjectsContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const Item = styled.div`
  width: 100%;
  height: 70px;
  :nth-child(even) {
    background-color: ${Theme.colors.white};
  }
  :nth-child(odd) {
    background-color: ${Theme.colors.grey1};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${Theme.space[2]}px;
  overflow: hidden;
  :hover {
    background-color: ${Theme.colors.green};
  }
`;

const Thumbnail = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 8px;
  object-fit: cover;
  margin: 8px;
`;

const IconContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: ${Theme.colors.green};
  margin-left: auto;
  margin-right: 12px;
`

const NoProjectsContainer = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoProjectsHeading = styled.p`
    margin-top: 32px;
    font-size: ${Theme.fontSizes[4]}px;

`

type Props = {
  incompleteProjects: IProject[];
  gotoProject(project: IProject): void;
};
export const IncompleteList: React.FC<Props> = ({ incompleteProjects, gotoProject }) => {
  const { updateProject } = useProjects();

  const markProjectComplete = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, project: IProject) => {
    event.stopPropagation();
    updateProject(project.id, { complete: !project.complete });
  };
  return (
    <ProjectsContainer>
      {incompleteProjects.length > 0 ? incompleteProjects.map((project) => {
        return (
          <Item
          onClick={() => gotoProject(project)}
          >
            <Thumbnail src={project.featureURL} alt="Thumbnail" />

            {project.title}

            <IconContainer>
            <Icon
              tooltip={project.complete ? 'Set Incomplete' : 'Set Complete'}
              style={project.complete ? 'cross' : 'tick'}
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => markProjectComplete(event, project)}
            />
            </IconContainer>
            
          </Item>
        );
      }) : <NoProjectsContainer>
            <NoProjectsHeading>No incomplete projects found</NoProjectsHeading> 
          </NoProjectsContainer>}
    </ProjectsContainer>
  );
};
