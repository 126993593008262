import React from 'react';
import styled from 'styled-components';

const Polygon = styled.polygon`
  fill: none;
  stroke-miterlimit: 10;
  fill-rule: evenodd;
`;

type Props = {};

export const Down: React.FC<Props> = () => {
  return (
    <g>
      <Polygon points="11.5 18.3 25 31.8 38.5 18.3 11.5 18.3" />
    </g>
  );
};
