import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

const Polyline = styled.polyline`
  fill: none;
  stroke-miterlimit: 10;
  fill-rule: evenodd;
`;

type Props = {};

export const Upload: React.FC<Props> = () => {
  return (
    <g>
      <Polyline points="38.5 25 25 11.5 11.5 25" />
      <Line x1="11.5" y1="38.5" x2="38.5" y2="38.5" />
      <Line x1="25" y1="11.5" x2="25" y2="33" />
    </g>
  );
};
