import React, { FC, useCallback } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useDropzone } from 'react-dropzone';

type Props = {
  upload(file: File): void;
  allowClick?: boolean;
  height?: string;
  disabled?: boolean;
};

const VideoDropzone: FC<Props> = ({ upload, children, allowClick = false, height = '100%', disabled = false }) => {
  const onDrop = useCallback(
    (acceptedFiles: []) => {
      acceptedFiles.forEach((file) => {
        upload(file);
      });
    },
    [upload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: (file) => {
      console.log(file);
    },
    accept: '.mxf,video/*,audio/*',
    multiple: true,
    noClick: !allowClick,
    disabled: disabled,
  });

  return (
    <Box
      {...getRootProps()}
      width={1 / 1}
      height={height}
      bg={'transparent'}
      sx={{
        position: 'relative',
        filter: isDragActive ? 'brightness(0.8)' : 'brightness(1)',
        ':focus': { outline: 'none' },
      }}
    >
      <Flex variant={'dotted2'} alignItems="center" justifyContent="center">
        <Text color={'grey2'} textAlign="center">
          Drop videos or audio
        </Text>
      </Flex>
      <input {...getInputProps()} />
      {children}
    </Box>
  );
};

export default VideoDropzone;
