import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from './icon';
import { Text, Box } from 'rebass/styled-components';
import firebase from 'firebase/app';
import prettyBytes from 'pretty-bytes';
import { deleteModal } from 'util/modal';

const UploadContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 60px;
  :nth-child(odd) {
    background-color: ${(p) => p.theme.colors.white};
  }
  :nth-child(even) {
    background-color: ${(p) => p.theme.colors.grey1};
  }
`;

const UploadContent = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.space[3]}px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`;

const UploadTitle = styled(Text)`
  flex-grow: 1;
  user-select: none;
`;

const UploadLoader = styled(Box)<{ percent: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.colors.green};
  transform-origin: left;
  transform: scaleX(${(p) => p.percent / 100});
  opacity: ${(p) => (p.percent === 0 || p.percent === 100 ? 0 : 0.2)};
  transition: transform 0.5s ease;
  z-index: 1;
`;

type Props = {
  upload: firebase.storage.UploadTask;
  fileName: string;
  uploading(state: boolean): void;
};

const Upload: React.FC<Props> = ({ upload, fileName, uploading }) => {
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState('');
  const [total, setTotal] = useState('');
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    upload.on(
      'state_changed',
      (snapshot) => {
        uploading(true);
        const totalBytes = snapshot.totalBytes;
        const transferred = snapshot.bytesTransferred;
        setProgress((transferred / totalBytes) * 100);
        setUploaded(prettyBytes(transferred));
        setTotal(prettyBytes(totalBytes));
      },
      (error) => {
        uploading(false);
        setComplete(true);
      },
      () => {
        uploading(false);
        setComplete(true);
      },
    );
  }, []);

  const cancel = async () => {
    const confirm = await deleteModal('Cancel upload?');
    if (confirm) {
      upload.cancel();
    }
  };
  if (complete) return <></>;

  return (
    <UploadContainer>
      <UploadContent>
        <UploadTitle>{fileName}</UploadTitle>
        <Box mr={[2]} />
        <Text>
          {uploaded} / {total}
        </Text>
        {progress < 100 && (
          <>
            <Box mr={[2]} />
            <Icon style={'cross'} onClick={cancel} colour={IconColour.grey} tooltip={'Cancel'} />
          </>
        )}
      </UploadContent>
      <UploadLoader percent={progress} />
    </UploadContainer>
  );
};

export default Upload;
