import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

const Rect = styled.rect`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Delete: React.FC<Props> = () => {
  return (
    <g>
      <Rect x="17.481" y="17.83" width="15.038" height="19.642" />
      <Rect x="14.83" y="12.34" width="20.34" height="5.491" />
      <Line x1="22.226" y1="17.83" x2="22.226" y2="37.472" />
      <Line x1="27.774" y1="17.83" x2="27.774" y2="37.472" />
    </g>
  );
};
