import React from 'react';
import styled from 'styled-components';

const PolyLine = styled.polyline`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Tick: React.FC<Props> = () => {
  return (
    <g>
      <PolyLine points="39.86 12.621 22.52 37.379 10.14 28.709" fill-rule="evenodd"/>
    </g>
  );
};
