import React, { ChangeEvent, useState } from 'react';
import { Input } from '@rebass/forms/styled-components';
import useAuth from '../../hooks/useAuth';
import { colors } from '../../util/theme';
import { Box, Flex, Button, Text, Card } from 'rebass/styled-components';
import isEmail from 'validator/lib/isEmail';
import styled from 'styled-components';

const FormInput = styled(Input)<{ hasError: boolean }>`
  border-bottom: 1px solid ${colors.grey2};
  &[type='text']:focus {
    outline: ${({ hasError }) => hasError && `1px solid red`};
  }
  &[type='password']:focus {
    outline: ${({ hasError }) => hasError && `1px solid red`};
  }
`;

const CreateUser = () => {
  const { signUp, loading } = useAuth();
  const [checked, setChecked] = useState(false);
  const [authCreds, setAuthCreds] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    emailError: '',
    passwordError: '',
  });

  const validateEmail = (email: string): boolean => {
    return isEmail(email);
  };

  const validatePassword = (password: string): boolean => {
    return password.length >= 6;
  };

  const authChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'email') {
      setErrors((prev) => ({
        ...prev,
        emailError: validateEmail(value) ? '' : 'Invalid email address',
      }));
    }

    if (name === 'password') {
      setErrors((prev) => ({
        ...prev,
        passwordError: validatePassword(value) ? '' : 'Password must be at least 6 characters',
      }));
    }

    setAuthCreds((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleAddUser = async () => {
    await signUp(authCreds.email, authCreds.password, checked);
  };

  return (
    <Card mb={4} p={3}>
      <Box>
        <Text fontSize={18} fontWeight="bold" mb={[2]}>
          Add User & Password
        </Text>
        <Flex flexDirection={['column', 'row']} mb={[2]}>
          <Box mb={[3, 0]} mr={[0, 3]} flex={1}>
            <FormInput
              hasError={errors.emailError}
              onChange={authChangeHandler}
              name="email"
              type="text"
              placeholder="Email"
              value={authCreds.email}
            />
            <Text color={'red'} mt={1}>
              {errors.emailError ? errors.emailError : ''}
            </Text>
          </Box>
          <Box flex={1}>
            <FormInput
              hasError={errors.passwordError}
              onChange={authChangeHandler}
              name="password"
              type="password"
              placeholder="Password"
              value={authCreds.password}
            />
            <Text color={'red'} mt={1}>
              {errors.passwordError ? errors.passwordError : ''}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" mb={3}>
          <input type="checkbox" checked={checked} onChange={handleChecked} />
          <Text ml={2}>Is Admin</Text>
        </Flex>
        <Button onClick={handleAddUser}>{loading ? 'Loading...' : 'Add User'}</Button>
      </Box>
    </Card>
  );
};

export default CreateUser;
