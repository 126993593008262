import { useFirebase } from 'react-redux-firebase';
import { storageBucketRef } from 'util/constants';
import firebase from 'firebase/app';
import path from 'path';

const useFeature = (projectID: string) => {
  const storage = useFirebase().storage();

  const uploadFeature = async (file: File, callback: (upload: firebase.storage.UploadTask) => void) => {
    try {
      const upload = storage
        .refFromURL(storageBucketRef.cobox_images)
        .child(file.name)
        .put(file, {
          customMetadata: {
            projectID: projectID,
          },
        });
      callback(upload as any);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    uploadFeature,
  };
};

export default useFeature;
