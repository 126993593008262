import { useState } from "react";
import { assetSort } from "util/constants";

export const useUtil =()=>{
    const [currentSort, setCurrentSort] = useState<{
        sortType: assetSort;
        direction: 'desc' | 'asc';
      }>({ sortType: assetSort.date, direction: 'asc' });
    
    return {currentSort, setCurrentSort};
}

export default useUtil;
