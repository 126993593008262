import React from 'react';
import styled from 'styled-components';
import { Icon } from './icon';
import { Box, Text } from 'rebass/styled-components';

const Container = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 70px;
`;

const TextComponent = styled(Text)`
  text-align: left;
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const TextContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  :hover ${TextComponent} {
    color: ${(p) => p.theme.colors.secondary};
  }
`;

type Props = {
  text: string;
  onClick(newState: 'asc' | 'desc'): void;
  state?: 'asc' | 'desc';
};

export const SortTextButton: React.FC<Props> = ({ text, onClick, state }) => {
  const click = () => {
    onClick(!state ? 'asc' : state === 'asc' ? 'desc' : 'asc');
  };
  return (
    <Container>
      <TextContainer onClick={click}>
        <TextComponent>{text}</TextComponent>
        {state && <Icon style={state === 'asc' ? 'up' : 'down'} onClick={() => {}} noBox={true} />}
      </TextContainer>
    </Container>
  );
};
