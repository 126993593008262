const boxVariants = {
  line: {
    height: 1,
    borderBottom: '0.5px solid',
    borderColor: 'grey2',
  },
  title: {
    height: 40,
    bg: 'primary',
  },
  dotted: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    outline: '2px dashed',
    outlineOffset: '-10px',
    outlineColor: 'white',
  },
  dotted2: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    outline: '2px dashed',
    outlineOffset: '-10px',
    outlineColor: 'grey2',
  },
};

export default boxVariants;
