import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    height: 100%;
`;

const Path = styled.path`
    fill: none;
    stroke: ${(p) => p.theme.colors.white};
    stroke-width: 20px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 0.3s ease;
`;

const Rect = styled.rect`
    fill: none;
    stroke: ${(p) => p.theme.colors.white};
    stroke-width: 10px;
    stroke-miterlimit: 10;
    transition: stroke 0.3s ease;
`;

const Container = styled.svg`
    height: 40px;
    width: 40px;
    cursor: pointer;
    @media (hover: hover) {
        :hover ${Path} {
            stroke: ${(p) => p.theme.colors.secondary};
        }
        :hover ${Rect} {
            stroke: ${(p) => p.theme.colors.secondary};
        }
    }
    :active ${Path} {
        stroke: ${(p) => p.theme.colors.green};
    }
    :active ${Rect} {
        stroke: ${(p) => p.theme.colors.green};
    }
`;

type Props = {
    onClick(): void;
};

export const CoboxIcon: React.FC<Props> = ({ onClick }) => {
    return (
        <Container onClick={onClick}>
            <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.7 296.7">
                <g>
                    <Path d="M148.3,148.4a50.1,50.1,0,0,0,50,50,50,50,0,1,0-50-50,48.9,48.9,0,0,1-7.3,26.1,50,50,0,1,1-18.7-70.1" />
                    <Rect x="10.3" y="10.3" width="276.1" height="276.05" />
                </g>
            </SVG>
        </Container>
    );
};
