import { connectRouter } from 'connected-react-router';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';

//reducers
import authSlice from './authSlice';
import dataSlice from './dataSlice';
import uploadSlice from './uploadSlice';
import utilSlice from './utilSlice';

export const reducerState = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authSlice,
  data: dataSlice,
  upload: uploadSlice,
  util: utilSlice,
});

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authSlice,
    data: dataSlice,
    upload: uploadSlice,
    util: utilSlice,
  });

export default rootReducer;
