import { useFirestore } from 'react-redux-firebase';
import uniqid from 'uniqid';
import moment from 'moment';
import { firestoreCollection, docType } from 'util/constants';
import { deleteDocModal } from 'util/modal';

const useNotes = (projectID: string) => {
  const firestore = useFirestore();

  const addNote = (note?: Partial<INote>) => {
    const id = `note-${uniqid()}`;
    const newNote: INote = {
      id: id,
      projectID: projectID,
      date: moment().unix(),
      title: '',
      docType: docType.note,
      body: '',
      ...note,
    };
    firestore.collection(firestoreCollection.notes).doc(id).set(newNote);
  };

  const removeNote = async (id: string) => {
    const confirm = await deleteDocModal(docType.note);
    if (confirm) {
      firestore.collection(firestoreCollection.notes).doc(id).delete();
    }
  };

  const updateNote = (id: string, note: Partial<INote>) => {
    firestore.collection(firestoreCollection.notes).doc(id).update(note);
  };

  return {
    addNote,
    removeNote,
    updateNote,
  };
};

export default useNotes;
