import React from 'react';
import styled from 'styled-components';

const Polyline = styled.polyline`
  fill: none;
  stroke-miterlimit: 10;
`;

const Rect = styled.rect`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Folder: React.FC<Props> = () => {
  return (
    <g>
      <Rect x="11.5" y="16.7" width="27" height="21.67" />
      <Polyline points="26.3 16.8 23.8 11.5 11.5 11.5 11.5 16.8" />
    </g>
  );
};
