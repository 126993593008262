import React, { useEffect } from 'react';
import styled from 'styled-components';

//icons
import { Add } from '../svg/add';
import { Filter } from '../svg/filter';
import { Delete } from '../svg/delete';
import { Cross } from '../svg/cross';
import { Tick } from '../svg/tick';
import { Up } from '../svg/up';
import { Down } from '../svg/down';
import { Play } from '../svg/play';
import { Download } from '../svg/download';
import { Upload } from '../svg/upload';
import { Pause } from '../svg/pause';
import { Screenshot } from '../svg/screenshot';
import { Fullscreen } from '../svg/fullscreen';
import { Folder } from '../svg/folder';
import { Question } from '../svg/question';
import { Move } from 'svg/move';
import useTooltip from 'hooks/useTooltip';

export enum IconColour {
  primary = 'primary',
  secondary = 'secondary',
  green = 'green',
  grey = 'grey',
}

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const Inner = styled.g<{ colour: IconColour }>`
  stroke-width: 2px;
  stroke: ${(p) =>
    p.colour === IconColour.primary
      ? p.theme.colors.white
      : p.colour === IconColour.secondary
      ? p.theme.colors.white
      : p.colour === IconColour.green
      ? p.theme.colors.green
      : p.theme.colors.grey3};
`;

const Outer = styled.g<{ colour: IconColour }>`
  stroke-width: 2px;
  stroke: ${(p) =>
    p.colour === IconColour.primary
      ? p.theme.colors.secondary
      : p.colour === IconColour.secondary
      ? p.theme.colors.white
      : p.colour === IconColour.green
      ? p.theme.colors.greenDark
      : p.theme.colors.grey3};
`;

const Polygon = styled.polygon`
  fill: none;
  stroke-miterlimit: 10;
`;

const Container = styled.div<{ height: string; colour: IconColour }>`
  min-height: ${(p) => p.height};
  min-width: ${(p) => p.height};
  height: ${(p) => p.height};
  width: ${(p) => p.height};
  box-sizing: border-box;
  cursor: pointer;
  :hover ${Inner} {
    stroke: ${(p) =>
      p.colour === IconColour.primary
        ? p.theme.colors.white
        : p.colour === IconColour.secondary
        ? p.theme.colors.secondary
        : p.colour === IconColour.green
        ? p.theme.colors.secondary
        : p.theme.colors.primary};
  }
  :hover ${Outer} {
    stroke: ${(p) =>
      p.colour === IconColour.primary
        ? p.theme.colors.white
        : p.colour === IconColour.secondary
        ? p.theme.colors.secondary
        : p.colour === IconColour.green
        ? p.theme.colors.secondary
        : p.theme.colors.primary};
  }
  :active ${Inner} {
    stroke: ${(p) =>
      p.colour === IconColour.primary
        ? p.theme.colors.secondary
        : p.colour === IconColour.secondary
        ? p.theme.colors.primaryDark
        : p.colour === IconColour.green
        ? p.theme.colors.primary
        : p.theme.colors.secondary};
  }
  :active ${Outer} {
    stroke: ${(p) =>
      p.colour === IconColour.primary
        ? p.theme.colors.secondary
        : p.colour === IconColour.secondary
        ? p.theme.colors.primaryDark
        : p.colour === IconColour.green
        ? p.theme.colors.primary
        : p.theme.colors.secondary};
  }
`;

type Props = {
  style:
    | 'add'
    | 'filter'
    | 'delete'
    | 'cross'
    | 'tick'
    | 'up'
    | 'down'
    | 'play'
    | 'upload'
    | 'download'
    | 'pause'
    | 'screenshot'
    | 'fullscreen'
    | 'folder'
    | 'question'
    | 'move';
  colour?: IconColour;
  height?: string;
  onClick(event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  noBox?: boolean;
  tooltip?: string;
};

export const Icon: React.FC<Props> = ({
  style,
  height = '25px',
  onClick,
  colour = IconColour.primary,
  noBox = false,
  tooltip,
}) => {
  const switchIcon = (): JSX.Element => {
    switch (style) {
      case 'add':
        return <Add />;

      case 'filter':
        return <Filter />;

      case 'delete':
        return <Delete />;

      case 'cross':
        return <Cross />;

      case 'tick':
        return <Tick />;

      case 'up':
        return <Up />;

      case 'down':
        return <Down />;

      case 'play':
        return <Play />;

      case 'upload':
        return <Upload />;

      case 'download':
        return <Download />;

      case 'pause':
        return <Pause />;

      case 'screenshot':
        return <Screenshot />;

      case 'fullscreen':
        return <Fullscreen />;

      case 'question':
        return <Question />;

      case 'folder':
        return <Folder />;

      case 'move':
        return <Move />;
        
      default:
        return <Add />;
    }
  };

  const { setTooltip } = useTooltip();

  return (
    <Container height={height} onClick={onClick} colour={colour} onMouseEnter={() => tooltip && setTooltip(tooltip)}>
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <Inner colour={colour}>{switchIcon()}</Inner>
        {!noBox && (
          <Outer colour={colour}>
            <Polygon points="25 0.5 0.5 0.5 0.5 49.5 49.5 49.5 49.5 0.5 25 0.5" />
          </Outer>
        )}
      </SVG> 
    </Container>
  );
};
