import React, { FC, useCallback } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { useDropzone } from 'react-dropzone';

type Props = {
  upload(file: File): void;
  allowClick?: boolean;
};

const FileDropZone: FC<Props> = ({ upload, children, allowClick = false }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      upload(acceptedFiles[0]);
    },
    [upload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDrop,
    accept: 'image/jpeg, image/png',
    multiple: false,
    noClick: !allowClick,
  });

  return (
    <Flex
      {...getRootProps()}
      width={1 / 1}
      height={'100%'}
      overflow={'hidden'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={'primary'}
      sx={{
        position: 'relative',
        filter: isDragActive ? 'brightness(0.8)' : 'brightness(1)',
        ':focus': { outline: 'none' },
        cursor: 'pointer',
      }}
    >
      {isDragActive && <Box variant={'dotted'} />}
      <input {...getInputProps()} />
      {children}
    </Flex>
  );
};

export default FileDropZone;
