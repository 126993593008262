import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from './icon';
import { durationType, videoSort, variant } from '../util/constants';
import moment from 'moment';
import { Box, Text } from 'rebass/styled-components';
import useVideosListener from 'hooks/useVideosListener';
import { Input } from '@rebass/forms/styled-components';
import { motion } from 'framer-motion';
import VideoContainer from './videoComponents/videoContainer';
import { VideoSortBar } from './videoComponents/videoSortBar';

const Bar = styled(Box)`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const Container = styled(Box)<{ dropped: boolean }>`
  width: 100%;
  box-sizing: border-box;
  :nth-child(odd) ${Bar} {
    background-color: ${(p) => (p.dropped ? p.theme.colors.green : p.theme.colors.white)};
  }
  :nth-child(even) ${Bar} {
    background-color: ${(p) => (p.dropped ? p.theme.colors.green : p.theme.colors.grey1)};
  }
`;

const InputContainer = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? '150px' : p.width)};
  box-sizing: border-box;
`;

const DateText = styled(Text)<{ dropped: boolean }>`
  text-align: center;
`;

const Dropdown = styled(motion.div)`
  background-color: ${(p) => p.theme.colors.offWhite};
  display: flex;
  flex-direction: column;
`;

type Props = {
  record: IRecord;
  deleteRecord(): void;
  updateRecord(id: string, record: Partial<IRecord>): void;
};

const Record: React.FC<Props> = ({ record, deleteRecord, updateRecord }) => {
  const [currentSort, setCurrentSort] = useState<{
    sortType: videoSort;
    direction: 'desc' | 'asc';
  }>({ sortType: videoSort.date, direction: 'asc' });

  const [uploading, setUploading] = useState(false);
  const { videos, updateSort } = useVideosListener(record.id);
  const [dropped, setDropped] = useState(false);
  const [keyNumber, setKeyNumber] = useState(record.keyNumber);
  const [title, setTitle] = useState(record.title);

  useEffect(() => {
    if (title && keyNumber) {
      if (record.title !== title) {
        setTitle(record.title);
      }
      if (record.keyNumber !== keyNumber) {
        setKeyNumber(record.keyNumber);
      }
    }
  }, [record]);

  useEffect(() => {
    updateSort(currentSort);
  }, [currentSort]);

  return (
    <>
      <Container
        dropped={dropped}
        sx={{
          border: '1px solid',
          borderColor: 'grey2',
          borderRadius: '5px',
          overflowY: 'hidden',
          boxShadow: 'main',
        }}
      >
        <Bar p={[3]} sx={{ borderBottom: dropped ? 'none' : '1px solid', borderColor: 'grey1' }}>
          <Icon
            style={dropped ? 'up' : 'down'}
            onClick={() => {
              if (!uploading) {
                setDropped((current) => !current);
              }
            }}
            colour={dropped ? IconColour.secondary : IconColour.grey}
          />
          <Box mr={[3]} />
          <InputContainer width={'150px'}>
            <Input
              value={keyNumber}
              placeholder={'Input Key/Cat'}
              onChange={(input: any) => setKeyNumber(input.target.value)}
              type="text"
              variant={variant.forms.inputFieldNoLine}
              onBlur={(input: any) => updateRecord(record.id, { keyNumber: input.target.value })}
              color={dropped ? 'white' : 'grey3'}
            />
          </InputContainer>
          <Box mr={[3]} />
          <InputContainer width={'100%'}>
            <Input
              value={title}
              placeholder={'Input Description...'}
              onChange={(input: any) => setTitle(input.target.value)}
              type="text"
              variant={variant.forms.inputFieldNoLine}
              onBlur={(input: any) => updateRecord(record.id, { title: input.target.value })}
              color={dropped ? 'white' : 'grey3'}
            />
          </InputContainer>
          <Box mr={[3]} />
          <InputContainer width={'100px'}>
            <DateText color={dropped ? 'white' : 'grey3'} dropped={dropped}>
              {moment.unix(record.date).format('DD-MM-YY')}
            </DateText>
          </InputContainer>
          <Box mr={[3]} />
          <Icon
            tooltip={'Delete Record'}
            style={'delete'}
            onClick={deleteRecord}
            colour={dropped ? IconColour.secondary : IconColour.grey}
          />
        </Bar>
        <Dropdown
          animate={dropped ? 'visible' : 'hidden'}
          transition={{ ease: 'easeInOut', duration: 0.25 }}
          variants={{ hidden: { height: 0 }, visible: { height: 400 } }}
        >
          <VideoSortBar
            currentSort={currentSort}
            update={(sortType, direction) => setCurrentSort({ sortType, direction })}
          />
          <VideoContainer files={videos} record={record} isUploading={setUploading} />
        </Dropdown>
      </Container>
    </>
  );
};

export default Record;
