const buttonVariants = {
  primary: {
    bg: 'primary',
    height: 40,
    fontSize: [1],
    '@media (hover: hover)': {
      ':hover': {
        bg: 'secondary',
      },
    },
    ':active': {
      bg: 'accent',
    },
    outline: 'none',
    cursor: 'pointer',
  },
  grey: {
    bg: 'grey2',
    fontSize: [1],
    '@media (hover: hover)': {
      ':hover': {
        bg: 'secondary',
      },
    },
    ':active': {
      bg: 'accent',
    },
    outline: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
    height: 40,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    wordWrap: 'no-wrap',
  },
  outlineOnWhite: {
    color: 'white',
    bg: 'transparent',
    height: 40,
    fontSize: [1],
    border: '0.5px solid',
    borderRadius: 0,
    outline: 'none',
    cursor: 'pointer',
    '@media (hover: hover)': {
      ':hover': {
        borderColor: 'secondary',
      },
    },
    ':active': {
      bg: 'green',
      borderColor: 'green',
    },
    transition: 'border-color 0.3s ease',
  },
  outlineOnGreen: {
    color: 'white',
    bg: 'transparent',
    height: 40,
    fontSize: [1],
    border: '0.5px solid',
    borderRadius: 0,
    outline: 'none',
    cursor: 'pointer',
    '@media (hover: hover)': {
      ':hover': {
        borderColor: 'secondary',
      },
    },
    ':active': {
      bg: 'secondary',
      borderColor: 'secondary',
    },
    transition: 'border-color 0.3s ease',
  },
};

export default buttonVariants;
