import React from 'react';
import styled from 'styled-components';
import { docType, firestoreCollection } from 'util/constants';
import moment from 'moment';
import { Text as TextMain } from 'rebass/styled-components';
import firebase from 'firebase/app';

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 45px;
  left: 0;
  background-color: black;
  z-index: 100;
  box-shadow: ${(p) => p.theme.shadows.main};
`;

const TextContainer = styled.div<{ width: string }>`
  width: ${(p) => (p.width === '100%' ? 'auto' : p.width)};
  min-width: ${(p) => (p.width === '100%' ? 'auto' : p.width)};
  flex-grow: ${(p) => (p.width === '100%' ? 1 : 'auto')};
  box-sizing: border-box;
`;

const Type = styled(TextMain)`
  user-select: none;
  color: ${(p) => p.theme.colors.grey3};
`;

const Text = styled(TextMain)`
  user-select: none;
  color: ${(p) => p.theme.colors.grey3};
`;

const DateText = styled(TextMain)`
  user-select: none;
  text-align: right;
  color: ${(p) => p.theme.colors.grey3};
`;

const Item = styled.div`
  width: 100%;
  height: 70px;
  :nth-child(even) {
    background-color: ${(p) => p.theme.colors.white};
  }
  :nth-child(odd) {
    background-color: ${(p) => p.theme.colors.grey1};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${(p) => p.theme.space[2]}px;
  overflow: hidden;
  :hover {
    background-color: ${(p) => p.theme.colors.green};
  }
  :hover ${Type} {
    color: ${(p) => p.theme.colors.white};
  }
  :hover ${Text} {
    color: ${(p) => p.theme.colors.white};
  }
  :hover ${DateText} {
    color: ${(p) => p.theme.colors.white};
  }
`;

const Thumbnail = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 8px;
  object-fit: cover;
  margin: 8px;
`;

type Props = {
  items: IProject[];
  click(projectID: string): void;
};

export const SearchList: React.FC<Props> = ({ items, click }) => {
  return (
    <Container>
      {items.map((item) => (
        <Item key={item.id} onClick={() => click(item.id)}>
          <Thumbnail src={item.featureURL} alt="Thumbnail" />
          <TextContainer width={'100%'}>
            <Text>{item.title}</Text>
          </TextContainer>
          <TextContainer width={'100px'}>
            <DateText>{moment.unix(item.date).format('DD-MM-YYYY')}</DateText>
          </TextContainer>
        </Item>
      ))}
    </Container>
  );
};
