import firebase from 'firebase/app';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/authSlice';

const useAuthListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(setUser({ email: user.email ?? '' }));
      } else {
        dispatch(setUser(undefined));
      }
    });
  }, []);
};

export default useAuthListener;
