import React, { FC, useState, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Icon } from './icon';
import { motion } from 'framer-motion';
import { setTooltip } from 'store/utilSlice';

const Container = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
`;

type Props = {
  tooltip: string;
  toggled: boolean;
};

const LowerTooltip: FC<Props> = ({ tooltip, toggled }) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [visible, setVisible] = useState(false);
  const [timeoutID, setTimeoutID] = useState<number>();

  useEffect(() => {
    if (!isInitialLoad) {
      if (tooltip) {
        if (timeoutID) {
          clearTimeout(timeoutID);
        }
        setVisible(true);
        setTimeoutID(
          setTimeout(() => {
            setVisible(false);
          }, 3000),
        );
      }
    } else {
      setIsInitialLoad(false);
    }
  }, [tooltip, toggled]);

  const closeTooltip = () => {
    setVisible(false);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  };

  return (
    <Container p={[2]}>
      <motion.div
        animate={visible ? 'enter' : 'exit'}
        initial={{ transform: 'translateY(50px)' }}
        variants={{
          enter: { transform: 'translateY(0px)' },
          exit: { transform: 'translateY(50px)' },
        }}
        transition={{ ease: 'easeInOut', duration: 0.3 }}
      >
        <Flex
          bg={'greyDark'}
          sx={{
            borderRadius: '5px',
            overflow: 'hidden',
            boxShadow: 'main',
            pointerEvents: 'all',
          }}
          py={[2]}
          px={[3]}
        >
          <Text color="white" mr={[3]}>
            {tooltip}
          </Text>
          <Icon style="cross" onClick={closeTooltip} />
        </Flex>
      </motion.div>
    </Container>
  );
};

export default LowerTooltip;
