import React from 'react';
import styled from 'styled-components';
import { StaticIcon } from './staticIcon';
import { IconColour } from './icon';
import { Box } from 'rebass/styled-components';
import useTooltip from 'hooks/useTooltip';

const Container = styled(Box)<{ loading: boolean }>`
  height: 100%;
  cursor: ${(p) => (p.loading ? 'default' : 'pointer')};
  background-color: rgb(220, 220, 220);
  :hover {
    background-color: ${(p) => (p.loading ? 'rgb(220, 220, 220)' : 'rgb(215, 215, 215)')};
  }
  :active {
    background-color: ${(p) => (p.loading ? 'rgb(220, 220, 220)' : p.theme.colors.primary)};
  }
  padding: 20px;
  position: relative;
  min-height: 200px;
`;

const Border = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  height: 100%;
`;

type Props = {
  add(): void;
  loading: boolean;
  scale: number;
};

export const AddProject: React.FC<Props> = ({ add, loading, scale }: Props) => {
  const { setTooltip } = useTooltip();
  const click = () => {
    if (!loading) add();
  };
  return (
    <Container onClick={click} loading={loading} onMouseEnter={() => setTooltip('Add Project')}>
      <Border>
        <StaticIcon
          height={`${60 * scale}px`}
          onClick={() => {
            //
          }}
          style={'add'}
          colour={IconColour.secondary}
          cursor={loading ? 'default' : 'pointer'}
        />
      </Border>
    </Container>
  );
};
