import { useEffect, useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { firestoreCollection, recordSort } from 'util/constants';
import { setRecords } from 'store/dataSlice';
import firebase from 'firebase/app';

const useRecordsListener = (projectID: string, sortType: recordSort, sortDirection: 'asc' | 'desc') => {
  const dispatch = useDispatch();
  const [recordsLoading, setRecordsLoading] = useState(true);

  useEffect(() => {
    const listener = firebase
      .firestore()
      .collection(firestoreCollection.records)
      .where('projectID', '==', projectID)
      .orderBy(sortType, sortDirection)
      .onSnapshot((snapshot) => {
        setRecordsLoading(true);
        let records: IRecord[] = [];
        snapshot.forEach((doc) => {
          records.push(doc.data() as IRecord);
        });
        dispatch(setRecords(records));
        setRecordsLoading(false);
      });
    return () => {
      listener();
    };
  }, []);

  return {
    recordsLoading,
  };
};

export default useRecordsListener;
