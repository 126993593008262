import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Add: React.FC<Props> = () => {
  return (
    <g>
      <Line x1="25" y1="11.5" x2="25" y2="38.5" />
      <Line x1="38.5" y1="25" x2="11.5" y2="25" />
    </g>
  );
};
