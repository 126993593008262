import React, { useEffect, useState, FC } from 'react';
import { createPortal } from 'react-dom';

const Modal: FC = ({ children }) => {
  const [element, setElement] = useState(document.createElement('div'));
  useEffect(() => {
    const modalRoot = document.getElementById('modal');

    if (modalRoot) {
      modalRoot.appendChild(element);
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeChild(element);
      }
    };
  }, []);
  return createPortal(children, element);
};
export default Modal;
