import { useFirebase, useFirestore } from 'react-redux-firebase';
import { storageBucketRef, firestoreCollection } from 'util/constants';
import { deleteModal } from 'util/modal';
import firebase from 'firebase/app';

const useAsset = () => {
  const storage = useFirebase().storage();
  const firestore = useFirestore();

  const uploadAsset = async (
    file: File,
    projectID: string,
    callback: (upload: firebase.storage.UploadTask) => void,
  ) => {
    try {
      const upload = storage
        .refFromURL(storageBucketRef.cobox_assets)
        .child(file.name)
        .put(file, {
          customMetadata: {
            projectID: projectID,
          },
        });
      callback(upload as any);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAsset = async (id: string) => {
    const confirm = await deleteModal(
      'This is an archived file, deleting will incur significant, monetary cost. Are you sure you want to delete? This is irreversible',
    );
    if (confirm) {
      firestore.collection(firestoreCollection.assets).doc(id).delete();
    }
  };

  const updateAsset = (id: string, Asset: Partial<IAsset>) => {
    firestore.collection(firestoreCollection.assets).doc(id).update(Asset);
  };

  return {
    uploadAsset,
    deleteAsset,
    updateAsset,
  };
};

export default useAsset;
