import React, { useEffect, useState, useRef } from 'react';
import { Box } from 'rebass';
import useProjectsListener from 'hooks/useProjectsListener';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import ProjectInfo from 'components/projectInfo';
import RecordsHeader from 'components/recordsHeader';
import { Notes } from 'components/notes';
import useNotesListener from 'hooks/useNotesListener';
import useNotes from 'hooks/useNotes';
import useRecordsListener from 'hooks/useRecordsListener';
import useRecords from 'hooks/useRecords';
import { recordSort } from 'util/constants';
import useProjectListener from 'hooks/useProjectListener';
import AddRecord from 'components/addRecord.tsx';
import Record from 'components/record';
import { setRecords, setProject, setNotes } from 'store/dataSlice';
import { placeholderProject } from 'util/placeholder';
import Assets from 'components/asset/assets';
import Selector from 'components/selector';
import { motion, AnimatePresence } from 'framer-motion';
import { Gallery } from 'components/gallery';

const Project = ({ match }: any) => {
  const id = match.params.id;
  const [rSort, setRecordSort] = useState(recordSort.date);
  const [rdirection, setRDirection] = useState<'desc' | 'asc'>('asc');
  const dispatch = useDispatch();
  const [sections, setSections] = useState<string[]>([]);

  useProjectListener(id);
  const { project } = useSelector((state: AppState) => state.data);

  const { notesLoading } = useNotesListener(id);
  const { notes } = useSelector((state: AppState) => state.data);
  const { addNote, updateNote, removeNote } = useNotes(id);

  const { records } = useSelector((state: AppState) => state.data);
  const { recordsLoading } = useRecordsListener(id, rSort, rdirection);
  const { addRecord, updateRecord, removeRecord } = useRecords();

  useEffect(() => {
    return () => {
      dispatch(setRecords([]));
      dispatch(setProject(placeholderProject));
      dispatch(setNotes([]));
      dispatch(setProject(undefined));
    };
  }, []);

  if (!project) return <Box></Box>;

  return (
    <>
      <div id="modal"></div>
      <Box p={[2]}>
        <Selector onSelect={(selections) => setSections(selections)} />
        <Box mb={[3]} />
        <AnimatePresence>
          {sections.includes('Info') && (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              exit={{ height: 0 }}
            >
              <ProjectInfo project={project} />
              <Box mb={[4]} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {sections.includes('Videos') && (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              exit={{ height: 0 }}
            >
              <RecordsHeader addRecord={() => addRecord(id)} loading={recordsLoading} />
              <Box mb={[2]} />
              {records.map((record, index) => (
                <Box key={`record${record.id}${index}`} mb={[2]}>
                  <Record
                    record={record}
                    deleteRecord={() => removeRecord(record.id)}
                    updateRecord={(id, record) => updateRecord(id, record)}
                  />
                </Box>
              ))}
              <AddRecord onClick={() => addRecord(id)} />
              <Box mb={[4]} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {sections.includes('Assets') && (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              exit={{ height: 0 }}
            >
              <Assets projectID={project.id} />
              <Box mb={[4]} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {sections.includes('Notes') && (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition={{ ease: 'easeInOut', duration: 0.5 }}
              exit={{ height: 0 }}
            >
              <Notes
                addNote={() => addNote()}
                notes={notes}
                updateNote={(note) => updateNote(note.id, note)}
                deleteNote={removeNote}
                loading={notesLoading ? true : false}
              />
              <Box mb={[4]} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <Gallery loading={false} projectId={project.id} />
            <Box mb={[4]} />
          </motion.div>
        </AnimatePresence>

      </Box>
    </>
  );
};

export default Project;
