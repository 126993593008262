import React, { useEffect } from 'react'
import styled from 'styled-components'
import Theme from 'util/theme'
import { Icon } from './icon';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  z-index: 9; /* Ensure the overlay is behind the dialog container */
`;

const DialogContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 80vw;
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    background-color: ${Theme.colors.offWhite};
    box-shadow: 0 0 20px #00000040;
    z-index: 10;
`


const TopBar = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  background-color: ${Theme.colors.primary};
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const DialogTitle = styled.p`
    text-align: center;
    color: ${Theme.colors.white};
    font-weight: 400;
`;

type Props = {
    title: string
    children: React.ReactNode;
    exit(): void;
  };

export const Dialog: React.FC<Props> = ({title, children, exit}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, []);
  return (
    <>
        <Overlay/>
        <DialogContainer>
             <TopBar>
                <DialogTitle>{title}</DialogTitle>
                <Icon style={'cross'} onClick={() => exit()} tooltip={'Exit'} />
              </TopBar>
            {children}
        </DialogContainer>
    </>
  )
}
