import { useEffect, useState } from 'react';
import { firestoreCollection, assetSort } from 'util/constants';
import { useFirebase } from 'react-redux-firebase';
import firebase from 'firebase';

const useGalleryListner = (projectID: string, sort: assetSort, direction: 'asc' | 'desc') => {
  const [imagesLoading, setImagesLoading] = useState(true);
  const [images, setImages] = useState<IAsset[]>([]);
  const storage = useFirebase().storage();

  useEffect(() => {
    let isMounted = true;
    let updatedAssets: IAsset[] = [...images];
    const listener = firebase
      .firestore()
      .collection(firestoreCollection.gallery)
      .where('projectID', '==', projectID)
      .orderBy(sort, direction)
      .onSnapshot(async (snapshot) => {
        setImagesLoading(true);
        const changes = snapshot.docChanges();

        const assetPromises = changes.map(async (change) => {
          const doc = change.doc;
          const assetData = doc.data() as IAsset;
          const imageId = doc.id;

          if (change.type === 'added' || change.type === 'modified') {
            try {
              const downloadURL = await firebase
                .storage()
                .refFromURL(`gs://cobox_gallery/${assetData.objectRef}`)
                .getDownloadURL();

              const updatedAsset = { ...assetData, downloadURL, imageId };

              if (change.type === 'added') {
                const index = updatedAssets.findIndex((asset) => asset.imageId === imageId);
                if (index === -1) {
                  updatedAssets.push(updatedAsset);
                }
              } else {
                const index = updatedAssets.findIndex((asset) => asset.imageId === imageId);
                if (index > -1) {
                  updatedAssets[index] = updatedAsset;
                }
              }
            } catch (error) {
              console.error('Error fetching download URL:', error);
            }
          }

          if (change.type === 'removed') {
            updatedAssets = updatedAssets.filter((asset) => asset.imageId !== imageId);
          }
        });

        await Promise.all(assetPromises);

        if (isMounted) {
          setImages([...updatedAssets]);
          setImagesLoading(false);
        }
      });

    return () => {
      isMounted = false;
      listener();
    };
  }, [projectID, sort, direction, storage]);

  return {
    images,
    imagesLoading,
    setImagesLoading,
  };
};

export default useGalleryListner;
