import { useFirestore } from 'react-redux-firebase';
import uniqid from 'uniqid';
import moment from 'moment';
import { firestoreCollection, docType } from 'util/constants';
import { deleteDocModal } from 'util/modal';

const useProjects = () => {
  const firestore = useFirestore();

  const addProject = async (project?: Partial<IProject>): Promise<string> => {
    const id = `project-${uniqid()}`;
    const newProject: IProject = {
      id: id,
      budget: 0,
      client: '',
      complete: false,
      date: moment().unix(),
      title: '',
      docType: docType.project,
      featureURL: '/placeholder.jpg',
      jobNumber: '',
      tags: [],
      ...project,
      agency: '',
      customField1: {
        key: 'Director',
        value: ''
      },
      customField2: {
        key: 'Producer',
        value: ''
      },
      customField3: {
        key: 'DOP',
        value: ''
      },
    };
    await firestore.collection(firestoreCollection.projects).doc(id).set(newProject);
    return id;
  };

  const removeProject = async (id: string) => {
    const confirm = await deleteDocModal(docType.project);
    if (confirm) {
      firestore.collection(firestoreCollection.projects).doc(id).delete();
    }
  };

  const updateProject = (id: string, project: Partial<IProject>) => {
    firestore.collection(firestoreCollection.projects).doc(id).update(project);
  };

  const duplicateProject = async (project: IProject) => {
    const newId = `project-${uniqid()}`;
    project.id = newId
    await firestore.collection(firestoreCollection.projects).doc(newId).set(project!);

  };
  

  return {
    addProject,
    removeProject,
    updateProject,
    duplicateProject
  };
};

export default useProjects;
