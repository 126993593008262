import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.input<{
  width: string;
  colourStyle: 'primary' | 'secondary';
  noBorder: boolean;
}>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? 'auto' : p.width)};
  height: 100%;
  max-height: 40px;
  min-height: 40px;
  box-sizing: border-box;
  color: ${(p) => (p.colourStyle === 'primary' ? p.theme.colors.grey3 : p.theme.colors.white)};
  background-color: transparent;
  border: 0.5px solid
    ${(p) => (p.noBorder ? 'transparent' : p.colourStyle === 'primary' ? p.theme.colors.grey2 : p.theme.colors.white)};
  padding: ${(p) => (p.noBorder ? 0 : p.theme.space[2])}px;
  ::-webkit-clear-button {
    -webkit-appearance: none;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    opacity: 0.5;
  }
  :focus {
    outline: none;
    border: 0.5px solid ${(p) => (p.noBorder ? 'transparent' : p.theme.colors.secondary)};
    color: ${(p) =>
      p.noBorder
        ? p.colourStyle === 'primary'
          ? p.theme.colors.primary
          : p.theme.colors.white
        : p.colourStyle === 'primary'
        ? p.theme.colors.grey3
        : p.theme.colors.white};
  }
  font-family: 'Poppins', sans-serif;
  ::selection {
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
  }
`;

type Props = {
  value: number;
  name: string;
  placeholder?: string;
  continuousUpdate?(input: number): void;
  update(input: number): void;
  width?: string;
  style?: 'primary' | 'secondary';
  noBorder?: boolean;
};

export const NumberInput: React.FC<Props> = ({
  value,
  name,
  placeholder = 'Write here...',
  continuousUpdate,
  update,
  width = '100%',
  style = 'primary',
  noBorder = false,
}) => {
  const inputRef = useRef(null);
  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, []);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(parseInt(e.target.value));
  };

  const detectEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      update(input);

      const ref = inputRef.current! as HTMLInputElement;
      ref.blur();
    }
  };

  useEffect(() => {
    if (continuousUpdate) {
      continuousUpdate(input);
    }
  }, [input]);

  const onBlur = () => {
    if (input !== value) {
      update(input);
    }
  };

  return (
    <Container
      type={'number'}
      name={name}
      ref={inputRef}
      placeholder={placeholder}
      value={input >= 0 ? input : ''}
      onChange={onChange}
      onKeyPress={detectEnter}
      width={width}
      colourStyle={style}
      onBlur={onBlur}
      noBorder={noBorder}
    />
  );
};
