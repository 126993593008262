import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
  projects: IProject[];
  project: IProject | undefined;
  records: IRecord[];
  notes: INote[];
};

const initialState: state = {
  projects: [],
  project: undefined,
  records: [],
  notes: [],
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<IProject[]>) {
      state.projects = action.payload;
    },
    setProject(state, action: PayloadAction<IProject | undefined>) {
      state.project = action.payload;
    },
    setRecords(state, action: PayloadAction<IRecord[]>) {
      state.records = action.payload;
    },
    setNotes(state, action: PayloadAction<INote[]>) {
      state.notes = action.payload;
    },
    
  },
});

export const { setProjects, setProject, setRecords, setNotes } = slice.actions;

export default slice.reducer;
