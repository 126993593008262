import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { Provider } from 'react-redux';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { PersistGate } from 'redux-persist/integration/react';
import Store from 'store';
import { ConnectedRouter } from 'connected-react-router';
import Router from './router';
import { ThemeProvider } from 'styled-components';
import Theme from 'util/theme';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.storage();
  firebase.auth();
}

const { store, persistor, history } = Store();

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        {...{
          firebase,
          config: {
            userProfile: 'users',
            useFirestoreForProfile: true,
          },
          dispatch: store.dispatch,
          createFirestoreInstance,
        }}
      >
        <PersistGate persistor={persistor} loading={null}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={Theme}>
              <Router />
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
