import { useEffect, useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { firestoreCollection } from 'util/constants';
import { setNotes } from 'store/dataSlice';
import firebase from 'firebase/app';

const useNotesListener = (projectID: string) => {
  const dispatch = useDispatch();
  const [notesLoading, setNotesLoading] = useState(true);

  useEffect(() => {
    const listener = firebase
      .firestore()
      .collection(firestoreCollection.notes)
      .where('projectID', '==', projectID)
      .orderBy('date', 'desc')
      .onSnapshot((snapshot) => {
        setNotesLoading(true);
        let notes: INote[] = [];
        snapshot.forEach((doc) => {
          notes.push(doc.data() as INote);
        });
        dispatch(setNotes(notes));
        setNotesLoading(false);
      });
    return () => {
      listener();
    };
  }, []);

  return {
    notesLoading,
  };
};

export default useNotesListener;
