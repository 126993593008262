export enum docType {
  project = 'project',
  video = 'video',
  asset = 'asset',
  note = 'note',
  record = 'record',
  audio = 'audio',
  gallery = 'gallery',
}

export enum firestoreCollection {
  projects = 'projects',
  users = 'users',
  notes = 'notes',
  records = 'records',
  videos = 'videos',
  assets = 'assets',
  audio = 'audio',
  gallery = 'gallery',
  projectDefaults = 'defaults',
}

export enum storageBucket {
  cobox_videos = 'cobox_videos',
  cobox_assets = 'cobox_assets',
  cobox_images = 'cobox_images',
  cobox_audio = 'cobox_audio',
  cobox_gallery = 'cobox_gallery',
}

export enum storageBucketRef {
  cobox_videos = 'gs://cobox_videos',
  cobox_assets = 'gs://cobox_assets',
  cobox_images = 'gs://cobox_images',
  cobox_audio = 'gs://cobox_audio',
  cobox_gallery = 'gs://cobox_gallery',
}

export const variant = {
  button: {
    primary: 'primary',
    outlineOnWhite: 'outlineOnWhite',
    outlineOnGreen: 'outlineOnGreen',
  },
  forms: {
    inputOnPrimary: 'inputOnPrimary',
    inputField: 'inputField',
    inputFieldNoLine: 'inputFieldNoLine',
  },
  text: {
    error: 'error',
  },
  box: {
    line: 'line',
    title: 'title',
  },
};

export enum durationType {
  sec = 'sec',
  min = 'min',
  hour = 'hour',
}

export enum recordSort {
  keyNumber = 'keyNumber',
  title = 'title',
  duration = 'duration',
  date = 'date',
}

export enum videoSort {
  filename = 'fileName',
  date = 'date',
  size = 'size',
  ext = 'ext',
  category = 'category',
}

export enum fileSort {
  filename = 'filename',
  ext = 'ext',
  date = 'date',
  size = 'size',
}

export enum assetSort {
  filename = 'fileName',
  date = 'date',
  size = 'size',
}

export enum gallerySort {
  filename = 'fileName',
  date = 'date',
  size = 'size',
}
