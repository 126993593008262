import React from 'react';
import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Ring = styled.div<{ altStyle: boolean }>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${(p) => (p.altStyle ? p.theme.colors.primary : 'white')};
    border-radius: 50%;
    animation: ${Animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => (p.altStyle ? p.theme.colors.primary : 'white')} transparent transparent transparent;
  }
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;

type Props = {
  altStyle?: boolean;
};

export const Spinner: React.FC<Props> = ({ altStyle = false }) => {
  return (
    <Ring altStyle={altStyle}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Ring>
  );
};
