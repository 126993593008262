import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from '../icon';
import moment from 'moment';
import path from 'path';
import prettyBytes from 'pretty-bytes';
import axios from 'axios';
import { Text, Box } from 'rebass/styled-components';
import firebase from 'firebase/app';
import useAsset from 'hooks/useAssets';
import ProjectModal from './projectsModal';
import MovingModal from './movingModal';

const InputContainer = styled(Box)<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? '150px' : p.width)};
  box-sizing: border-box;
`;

const Container = styled(Box)`
  position: relative;
  :nth-child(odd) {
    background-color: ${(p) => p.theme.colors.white};
  }
  :nth-child(even) {
    background-color: ${(p) => p.theme.colors.grey1};
  }
`;

const VideoBar = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  position: relative;
`;

const LoadingBar = styled(Box)<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.green};
  transform-origin: left;
  transform: scaleX(${(p) => p.percent / 100});
  opacity: ${(p) => (p.percent === 0 || p.percent === 100 ? 0 : 0.2)};
  transition: transform 0.5s ease, opacity 1s ease;
`;

const VideoText = styled(Text)`
  color: ${(p) => p.theme.colors.grey3};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextComponent = styled.p`
  color: ${(p) => p.theme.colors.grey3};
  text-align: left;
  width: 100%;
  white-space: nowrap;
`;

type Props = {
  asset: IAsset;
};

interface Asset {
  id: string;
  // Add other fields if necessary
}

export const AssetComponent: React.FC<Props> = ({ asset }) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [url, setUrl] = useState('');
  const { deleteAsset, uploadAsset } = useAsset();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    const getUrl = async () => {
      const newURL = await firebase.storage().refFromURL(asset.objectRef).getDownloadURL();
      setUrl(newURL);
    };
    getUrl();
  }, []);

  const download = async () => {
    const response = await axios.get(url, {
      responseType: 'blob',
      onDownloadProgress: (progress) => {
        const percent = (progress.loaded / progress.total) * 100;
        setDownloadProgress(percent);
      },
    });
    const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadURL;
    link.setAttribute('download', asset.id);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const moveAssetToProject = async (asset: IAsset, projectTitle: string) => {
    try {
      // Fetch the project ID using the project title
      setIsMoving(true);
      const projectsRef = firebase.firestore().collection('projects');
      const querySnapshot = await projectsRef.where('title', '==', projectTitle).get();

      if (querySnapshot.empty) {
        console.error(`Project with title ${projectTitle} not found.`);
        return;
      }

      const projectDoc = querySnapshot.docs[0];
      const projectId = projectDoc.id;

      // Get current asset document
      const assetRef = firebase.firestore().collection('assets').doc(asset.id);
      const assetDoc = await assetRef.get();
      const previousProjectRef = assetDoc.data()?.project;

      // Delete the asset from the previous project
      if (previousProjectRef) {
        const previousProjectDoc = await previousProjectRef.get();
        if (previousProjectDoc.exists) {
          const previousProjectAssets = previousProjectDoc.data()?.assets || [];
          const updatedAssets = previousProjectAssets.filter((a: { id: string }) => a.id !== asset.id);

          await previousProjectRef.update({
            assets: updatedAssets,
          });

          // Optionally, delete the asset from storage if required
          await deleteAsset(asset.id);
        }
      }

      // Upload the asset to the new project
      const url = await firebase.storage().refFromURL(asset.objectRef).getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = path.basename(asset.objectRef);
      const file = new File([blob], fileName);

      uploadAsset(file, projectId, (upload: firebase.storage.UploadTask) => {
        upload.on(
          'state_changed',
          (snapshot) => {
            // Handle upload progress if needed
          },
          (error) => {
            console.error('Error uploading asset:', error);
          },
          async () => {
            const downloadURL = await upload.snapshot.ref.getDownloadURL();
            await assetRef.update({
              project: firebase.firestore().doc(`projects/${projectId}`),
              url: downloadURL,
            });

            // Add asset to the new project document
            const newProjectDoc = await projectDoc.ref.get();
            const newProjectAssets = newProjectDoc.data()?.assets || [];

            await projectDoc.ref.update({
              assets: [...newProjectAssets, { id: asset.id }],
            });
          },
        );
      });
      alert(`Asset with ID ${asset.id} moved to project with ID ${projectId}.`);
    } catch (error) {
      console.error('Error moving asset to project:', error);
    } finally {
      setIsMoving(false);
    }
  };

  const handleMove = () => {
    setIsModalOpen(true);
  };

  const handleSelectProject = (project: string) => {
    setSelectedProject(project);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (selectedProject) {
      moveAssetToProject(asset, selectedProject); // Move asset when project selected
    }
  }, [selectedProject]); // Ensure it runs when selectedProject changes

  return (
    <Container>
      <LoadingBar percent={downloadProgress} />
      <VideoBar px={[3]}>
        <InputContainer width={'100%'}>
          <VideoText>{path.basename(asset.id, path.extname(asset.id))}</VideoText>
        </InputContainer>
        <Box mr={[2]} />
        <InputContainer width={'70px'}>
          <TextComponent>{path.extname(asset.id)}</TextComponent>
        </InputContainer>
        <Box mr={[2]} />
        <InputContainer width={'70px'}>
          <TextComponent>{prettyBytes(parseInt(asset.size as any))}</TextComponent>
        </InputContainer>
        <Box mr={[2]} />
        <InputContainer width={'80px'}>
          <TextComponent>{moment.unix(asset.date).format('DD-MM-YY')}</TextComponent>
        </InputContainer>
        <Box mr={[3]} />
        <Icon style={'download'} onClick={download} colour={IconColour.grey} />
        <Box mr={[3]} />
        <Icon style={'delete'} onClick={() => deleteAsset(asset.id)} colour={IconColour.grey} />
        <Box mr={[3]} />
        <Icon style={'move'} onClick={handleMove} colour={IconColour.grey} />
      </VideoBar>
      {isModalOpen && <ProjectModal onClose={() => setIsModalOpen(false)} onSelect={handleSelectProject} />}
      {isMoving && <MovingModal />}
    </Container>
  );
};
