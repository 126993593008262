import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import Loading from 'components/loading';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 70%;
  max-width: 400px; /* Reduced width */
  max-height: 80%;
  overflow-y: auto; /* Added scroll */
  position: relative;
`;

const ModalBody = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`;

const MovingModal = () => {
  return createPortal(
    <ModalBackground>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalBody>
          <ModalContainer>
            <div>
              <p>Please wait while the file is moving</p>
              <p>MOVING...</p>
            </div>
          </ModalContainer>
        </ModalBody>
      </ModalContent>
    </ModalBackground>,
    document.body,
  );
};

export default MovingModal;
