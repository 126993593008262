import React, { FC, useState, useRef, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import Upload from '../Upload';
import firebase from 'firebase';
import { LoadingBar } from '../loadingBar';
import uniqid from 'uniqid';
import useAsset from 'hooks/useAssets';
import { AssetComponent } from './asset';
import AssetDropzone from './assetDropzone';

const Bar = styled(Box)`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p) => p.theme.space[3]}px;
  box-sizing: border-box;
`;

const TitleText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const AssetContainer: FC<{
  projectID: string;
  files: IAsset[];
  isUploading(state: boolean): void;
}> = ({ projectID, files, isUploading }) => {
  const [uploading, setUploading] = useState(false);
  const { uploadAsset } = useAsset();
  const [uploads, _setUploads] = useState<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>([]);
  const uploadsRef = React.useRef<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>(uploads);
  const setUploads = (uploads: { task: firebase.storage.UploadTask; id: string; fileName: string }[]) => {
    uploadsRef.current = uploads;
    _setUploads(uploads);
  };

  useEffect(() => {
    isUploading(uploading);
  }, [uploading]);

  const uploadSingleFile = (file: File) => {
    setUploading(true);
    uploadAsset(file, projectID, (task) => {
      setUploads([...uploadsRef.current, { task: task, id: uniqid(), fileName: file.name }]);
    });
  };

  return (
    <>
      <Box bg={'white'} width={1} height={'100%'}>
        <Flex height={'100%'} flexDirection="column">
          <LoadingBar loading={uploading} />
          <AssetDropzone upload={uploadSingleFile}>
            {files.map((file, index) => (
              <AssetComponent key={`video${file.id}${index}`} asset={file} />
            ))}
            {uploads.map((upload, index) => (
              <Upload key={`video${index}`} upload={upload.task} fileName={upload.fileName} uploading={setUploading} />
            ))}
          </AssetDropzone>
        </Flex>
      </Box>
    </>
  );
};

export default AssetContainer;
