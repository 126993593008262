import React, { useState, useEffect } from 'react';
import { Icon, IconColour } from './icon';
import { TextAreaComponent } from './textArea';
import { Text, Box, Flex, Button } from 'rebass/styled-components';
import { Textarea, Input } from '@rebass/forms/styled-components';
import styled from 'styled-components';
import { variant } from 'util/constants';

const Area = styled(Textarea)`
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  color: ${(p) => p.theme.colors.grey3};
  ::placeholder {
    color: ${(p) => p.theme.colors.grey2};
    opacity: 0.5;
  }
  :focus {
    outline: none;
    border: 0.5px solid ${(p) => p.theme.colors.secondary};
  }
  font-family: 'Poppins', sans-serif;
  font-size: ${(p) => p.theme.fontSizes[2]};
`;

type Props = {
  note: INote;
  deleteNote(id: string): void;
  onSave(note: INote): void;
  onChange(): void;
};

const NoteForm: React.FC<Props> = ({ note, deleteNote, onSave, onChange }) => {
  const [title, setTitle] = useState(note.title);
  const [body, setBody] = useState(note.body);
  const [requireSave, setRequireSave] = useState(false);

  useEffect(() => {
    setTitle(note.title);
    setBody(note.body);
  }, [note]);

  useEffect(() => {
    if (title && body) {
      if (note.title !== title || note.body !== body) {
        onChange();
        setRequireSave(true);
      } else {
        setRequireSave(false);
      }
    }
  }, [title, body]);

  const save = () => {
    onSave({ ...note, title: title, body: body });
    setRequireSave(false);
  };

  return (
    <Box px={[2]} py={[3]} flex={1}>
      <Flex height={'100%'} flexDirection={'column'}>
        <Flex alignItems="center" px={[2]} mb={[2]}>
          {requireSave && (
            <Text color={'primary'} mr={[3]} sx={{ transform: 'translateY(-2px)' }}>
              Unsaved:
            </Text>
          )}
          <Box flex={1} mr={[3]}>
            <Input
              placeholder={'Write Title...'}
              value={title}
              onChange={(input: any) => setTitle(input.target.value)}
              variant={variant.forms.inputField}
              color={'grey3'}
            />
          </Box>
          <Box mr={[2]} />
          <Icon tooltip={'Delete Note'} style={'delete'} onClick={() => deleteNote(note.id)} colour={IconColour.grey} />
        </Flex>
        <Box flex={1} mb={[2]}>
          <Area
            placeholder={'Write note...'}
            value={body}
            onChange={(input: any) => setBody(input.target.value)}
            disabled={false}
            spellCheck={false}
          />
        </Box>
        <Flex px={[2]} justifyContent="flex-end">
          {requireSave && <Button onClick={save}>Save</Button>}
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoteForm;
