import React from 'react';
import styled from 'styled-components';

const Box = styled.div<{ switchOn: 'complete' | 'incomplete' | 'all' }>`
  height: 30px;
  width: 30px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 3px;
  transform: translateX(${(p) => (p.switchOn === 'all' ? 0 : p.switchOn === 'complete' ? 15 : 30)}px);
  box-sizing: border-box;
  transition: transform 0.2s ease;
`;

const Container = styled.div`
  height: 40px;
  width: 72px;
  background-color: transparent;
  border: 1px solid ${(p) => p.theme.colors.secondary};
  border-radius: 5px;
  display: flex;
  justify-content: 'flex-start';
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    border: 1px solid ${(p) => p.theme.colors.white};
  }
`;

type Props = {
  stateChange(state: 'complete' | 'incomplete' | 'all'): void;
  state: 'complete' | 'incomplete' | 'all';
};

export const TripleSwitch: React.FC<Props> = ({ stateChange, state }) => {
  const click = () => {
    if (state === 'all') stateChange('complete');
    if (state === 'complete') stateChange('incomplete');
    if (state === 'incomplete') stateChange('all');
  };

  return (
    <Container onClick={click}>
      <Box switchOn={state} />
    </Container>
  );
};
