import { useFirebase, useFirestore } from 'react-redux-firebase';
import { storageBucketRef, firestoreCollection } from 'util/constants';
import { deleteModal } from 'util/modal';
import firebase from 'firebase/app';

const useImages = () => {
  const storage = useFirebase().storage();
  const firestore = useFirestore();

  const uploadImages = async (
    file: File,
    projectID: string,
    callback: (upload: firebase.storage.UploadTask) => void,
  ) => {
    try {
      const storageRef = storage.refFromURL(storageBucketRef.cobox_gallery).child(file.name);

      const uploadTask = storageRef.put(file, {
        customMetadata: {
          projectID: projectID,
        },
      });

      callback(uploadTask as any);

      const snapshot = await uploadTask;

      const assetData: IAsset = {
        id: snapshot.ref.name,
        docType: file.type,
        objectRef: snapshot.ref.fullPath,
        date: Date.now(),
        size: file.size,
        projectID: projectID,
        type: file.type.split('/')[0],
      };

      const docRef = await firestore.collection(firestoreCollection.gallery).add(assetData);
      const generatedDocId = docRef.id;
      console.log('File uploaded and asset data saved to Firestore.', generatedDocId);
      return generatedDocId;
    } catch (error) {
      console.log('Error uploading file or saving asset data:', error);
    }
  };

  const deleteImages = async (id: string) => {
    const confirm = await deleteModal(
      'This is an archived file, deleting will incur significant, monetary cost. Are you sure you want to delete? This is irreversible',
    );
    if (confirm) {
      firestore.collection(firestoreCollection.gallery).doc(id).delete();
    }
  };

  const updateImages = (id: string, Asset: Partial<IAsset>) => {
    firestore.collection(firestoreCollection.gallery).doc(id).update(Asset);
  };

  return {
    uploadImages,
    deleteImages,
    updateImages,
  };
};

export default useImages;
