import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Cross: React.FC<Props> = () => {
  return (
    <g>
      <Line x1="12.278" y1="12.278" x2="37.722" y2="37.722" />
      <Line x1="37.722" y1="12.278" x2="12.278" y2="37.722" />
    </g>
  );
};
