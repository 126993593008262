import React, { useEffect } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const Inner = styled.g`
  stroke-width: 1px;
  stroke: ${(p) => p.theme.colors.primary};
  fill: none;
  stroke-miterlimit: 10;
  fill-rule: evenodd;
`;

const Container = styled.div`
  min-height: 25px;
  min-width: 25px;
  height: 25px;
  width: 25px;
  box-sizing: border-box;
`;

type Props = {};

export const ExclamationIcon: React.FC<Props> = ({}) => {
  return (
    <Container>
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <Inner>
          <g id="Layer_1-2" data-name="Layer 1">
            <circle cx="24.37" cy="40.04" r="3" />
            <line x1="24.37" y1="34.04" x2="24.37" y2="10.04" />
            <polygon points="24.37 0.5 0.5 48.04 48.24 48.04 24.37 0.5" />
          </g>
        </Inner>
      </SVG>
    </Container>
  );
};
