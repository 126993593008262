import React, { FC } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

type Props = {
  active: number;
  total: number;
  showIncomplete(): void;
};

const ProjectCount: FC<Props> = ({ active, total, showIncomplete }) => {
  return (
    <Flex mx={[3]} flex={1} sx={{ boxSizing: 'border-box' }}>
      <Text color="grey3" mr={[3]} onClick={showIncomplete}>
        incomplete:{' '}
        <Text as="span" color={'primary'}>
          {active}
        </Text>
      </Text>
      <Text color="grey3">
        total:{' '}
        <Text as="span" color={'primary'}>
          {total}
        </Text>
      </Text>
    </Flex>
  );
};

export default ProjectCount;
