import React, { FC, useCallback } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useDropzone } from 'react-dropzone';

type Props = {
  upload(file: File): void;
  allowClick?: boolean;
};

const GalleryDropzone: FC<Props> = ({ upload, children, allowClick = false }) => {
  const onDrop = useCallback(
    (acceptedFiles: []) => {
      acceptedFiles.forEach((file) => {
        upload(file);
      });
    },
    [upload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDrop,
    multiple: true,
    noClick: !allowClick,
  });

  return (
    <Box width={1} height="100%" bg="transparent" paddingBottom="50px">
      <Flex width={1} height="100%" flexDirection="row" overflow="hidden">
        <Box
          {...getRootProps()}
          width={1 / 3}
          height="100%"
          overflow="hidden"
          bg="transparent"
          sx={{
            position: 'relative',
            filter: isDragActive ? 'brightness(0.8)' : 'brightness(1)',
            ':focus': { outline: 'none' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #ccc',
          }}
        >
          <Flex variant={'dotted2'} alignItems="center" justifyContent="center">
            <Text color={'grey2'} textAlign="center">
              Drop images
            </Text>
          </Flex>
          <input {...getInputProps()} />
        </Box>

        <Box width={2 / 3} height="100%" overflow="auto" bg="white" padding={2}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default GalleryDropzone;
