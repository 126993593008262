import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
  user: IUser | undefined;
};

const initialState: state = {
  user: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | undefined>) {
      state.user = action.payload;
    },
  },
});

export const { setUser } = slice.actions;

export default slice.reducer;
