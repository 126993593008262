import { useFirebase, useFirestore } from 'react-redux-firebase';
import { storageBucketRef, firestoreCollection } from 'util/constants';
import { deleteModal } from 'util/modal';
import firebase from 'firebase/app';

const useVideo = () => {
  const storage = useFirebase().storage();
  const firestore = useFirestore();

  const uploadVideo = async (file: File, recordID: string, callback: (upload: firebase.storage.UploadTask) => void) => {
    try {
      const upload = storage
        .refFromURL(storageBucketRef.cobox_videos)
        .child(file.name)
        .put(file, {
          customMetadata: {
            recordID: recordID,
          },
        });
      callback(upload as any);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVideo = async (id: string, isPreview: boolean, deleteMessage?: '') => {
    const confirm = await deleteModal(
      !isPreview
        ? deleteMessage
          ? deleteMessage
          : 'This is an archived file, deleting will incur significant, monetary cost. Are you sure you want to delete? This is irreversible'
        : 'Are you sure you want to delete? This is irreversible',
    );
    if (confirm) {
      firestore.collection(firestoreCollection.videos).doc(id).delete();
    }
  };

  const updateVideo = (id: string, video: Partial<IVideo>) => {
    firestore.collection(firestoreCollection.videos).doc(id).update(video);
  };

  return {
    uploadVideo,
    deleteVideo,
    updateVideo,
  };
};

export default useVideo;
