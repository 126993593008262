import React from 'react';
import { Switch, Route } from 'react-router';
import Home from './home';
import useAuthListener from 'hooks/useAuthListener';
import ProtectedRoute from 'components/protectedRoute';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import Login from './login';
import { Box } from 'rebass';
import Header from 'components/header';
import Project from './project';
import LowerTooltip from 'components/lowerTooltip';
import Admin from './admin';

const Router = () => {
  useAuthListener();
  const { user } = useSelector((state: AppState) => state.auth);
  const { tooltip, tooltipToggle } = useSelector((state: AppState) => state.util);

  return (
    <Box>
      {user && (
        <>
          <Header />
          <Box height={50} />
        </>
      )}
      <LowerTooltip tooltip={tooltip} toggled={tooltipToggle} />
      <Switch>
        <Route exact path="/login" component={Login} />
        <ProtectedRoute
          isAuthenticated={!!user}
          exact
          authenticationPath="/login"
          path="/project/:id"
          component={Project}
        />
        <ProtectedRoute
          isAuthenticated={!!user}
          isAdminRoute={true}
          authenticationPath="/login"
          path="/admin"
          component={Admin}
        />
        <ProtectedRoute
          isAuthenticated={!!user}
          authenticationPath="/login"
          path="/"
          component={Home}
        />
      </Switch>
    </Box>
  );
};

export default Router;
