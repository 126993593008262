import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { createPortal } from 'react-dom';
import { Icon, IconColour } from '../icon';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 70%;
  max-width: 400px; /* Reduced width */
  max-height: 80%;
  overflow-y: auto; /* Added scroll */
  position: relative;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  padding-inline: 10px;
`;

const ModalBody = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 10px;
  border-radius: 5px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ProjectItem = styled.div`
  padding: 10px;
  border: 0.5px solid #ccc;
  margin-block: 5px;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
`;

type ProjectModalProps = {
  onClose: () => void;
  onSelect: (project: string) => void;
};

const ProjectModal: React.FC<ProjectModalProps> = ({ onClose, onSelect }) => {
  const [search, setSearch] = useState('');
  const [projects, setProjects] = useState<string[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      const snapshot = await firebase.firestore().collection('projects').get();
      const projectNames = snapshot.docs.map((doc) => doc.data().title);
      setProjects(projectNames);
      setFilteredProjects(projectNames);
      setLoading(false);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const filtered = projects.filter((project) => project.toLowerCase().includes(search.toLowerCase()));

    const sortedProjects = filtered.sort((a, b) => {
      if (a.toLowerCase().indexOf(search.toLowerCase()) === 0) return -1;
      if (b.toLowerCase().indexOf(search.toLowerCase()) === 0) return 1;
      return 0;
    });

    setFilteredProjects(sortedProjects);
  }, [search, projects]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return createPortal(
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Icon style={'cross'} onClick={onClose} tooltip={'close modal'} colour={IconColour.grey} />
        </div>
        <ModalHeader>Projects List</ModalHeader>
        <ModalBody>
          <ModalContainer>
            <SearchInput type="text" placeholder="Search projects..." value={search} onChange={handleSearchChange} />
            {loading ? (
              <LoadingMessage>Loading projects...</LoadingMessage>
            ) : (
              filteredProjects.sort().map((project) => (
                <ProjectItem key={project} onClick={() => onSelect(project)}>
                  {project}
                </ProjectItem>
              ))
            )}
          </ModalContainer>
        </ModalBody>
      </ModalContent>
    </ModalBackground>,
    document.body,
  );
};

export default ProjectModal;
