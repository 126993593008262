import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.input`
  cursor: pointer;
  background-color: ${(p) => p.theme.colors.primary};
  height: 40px;
  display: inline-block;
  padding: ${(p) => p.theme.space[2]}px;
  :hover {
    background-color: ${(p) => p.theme.colors.green};
  }
  :active {
    background-color: ${(p) => p.theme.colors.secondary};
  }
  color: ${(p) => p.theme.colors.white};
  background-color: transparent;
  outline: none;
  border: 1px solid ${(p) => p.theme.colors.secondary};
`;

type Props = {
  text: string;
  onClick(): void;
};

export const ResetButton: React.FC<Props> = ({ text, onClick }) => {
  return <ButtonStyle type="button" onClick={onClick} value={text} />;
};
