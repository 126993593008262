import moment from 'moment';
import { docType } from './constants';

export const placeholderProject: IProject = {
  id: '',
  budget: 0,
  client: '',
  complete: false,
  date: moment().unix(),
  docType: docType.project,
  featureURL: '/placeholder.jpg',
  jobNumber: '',
  tags: [],
  title: '',
  agency: '',
  customField1: {
    key: 'Director',
    value: ''
  },
  customField2: {
    key: 'Producer',
    value: ''
  },
  customField3: {
    key: 'DOP',
    value: ''
  },
};

export const placeholderNote: INote = {
  id: 'notePlaceholder',
  title: '',
  body: '',
  docType: docType.note,
  projectID: '',
  date: 0,
};
