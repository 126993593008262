import React, { useEffect } from 'react';
import styled from 'styled-components';
import reactTooltip from 'react-tooltip';

//icons
import { Add } from '../svg/add';
import { Filter } from '../svg/filter';
import { Delete } from '../svg/delete';
import { Cross } from '../svg/cross';
import { Tick } from '../svg/tick';
import { Up } from '../svg/up';
import { Down } from '../svg/down';
import { Play } from '../svg/play';
import { Download } from '../svg/download';
import { Upload } from '../svg/upload';
import { Pause } from '../svg/pause';
import { Screenshot } from '../svg/screenshot';
import { Fullscreen } from '../svg/fullscreen';
import { IconColour } from './icon';
import useTooltip from 'hooks/useTooltip';

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const Inner = styled.g<{ colour: IconColour }>`
  stroke-width: 2px;
  stroke: ${(p) =>
    p.colour === IconColour.primary
      ? p.theme.colors.white
      : p.colour === IconColour.secondary
      ? p.theme.colors.white
      : p.colour === IconColour.green
      ? p.theme.colors.green
      : p.theme.colors.grey3};
`;

const Outer = styled.g<{ colour: IconColour }>`
  stroke-width: 2px;
  stroke: ${(p) =>
    p.colour === IconColour.primary
      ? p.theme.colors.secondary
      : p.colour === IconColour.secondary
      ? p.theme.colors.white
      : p.colour === IconColour.green
      ? p.theme.colors.greenDark
      : p.theme.colors.grey3};
`;

const Polygon = styled.polygon`
  fill: none;
  stroke-miterlimit: 10;
`;

const Container = styled.div<{ height: string; colour: IconColour }>`
  min-height: ${(p) => p.height};
  min-width: ${(p) => p.height};
  height: ${(p) => p.height};
  width: ${(p) => p.height};
  box-sizing: border-box;
`;

type Props = {
  style:
    | 'add'
    | 'filter'
    | 'delete'
    | 'cross'
    | 'tick'
    | 'up'
    | 'down'
    | 'play'
    | 'upload'
    | 'download'
    | 'pause'
    | 'screenshot'
    | 'fullscreen';
  colour?: IconColour;
  height?: string;
  onClick(): void;
  noBox?: boolean;
  tooltip?: string;
  cursor?: string;
};

export const StaticIcon: React.FC<Props> = ({
  style,
  height = '25px',
  onClick,
  colour = IconColour.primary,
  noBox = false,
  tooltip,
  cursor = 'pointer',
}) => {
  useEffect(() => {
    reactTooltip.rebuild();
  }, []);

  const { setTooltip } = useTooltip();

  const switchIcon = (): JSX.Element => {
    switch (style) {
      case 'add':
        return <Add />;

      case 'filter':
        return <Filter />;

      case 'delete':
        return <Delete />;

      case 'cross':
        return <Cross />;

      case 'tick':
        return <Tick />;

      case 'up':
        return <Up />;

      case 'down':
        return <Down />;

      case 'play':
        return <Play />;

      case 'upload':
        return <Upload />;

      case 'download':
        return <Download />;

      case 'pause':
        return <Pause />;

      case 'screenshot':
        return <Screenshot />;

      case 'fullscreen':
        return <Fullscreen />;

      default:
        return <Add />;
    }
  };

  return (
    <Container
      height={height}
      onClick={onClick}
      colour={colour}
      style={{ cursor: cursor }}
      onMouseEnter={() => tooltip && setTooltip(tooltip)}
    >
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <Inner colour={colour}>{switchIcon()}</Inner>
        {!noBox && (
          <Outer colour={colour}>
            <Polygon points="25 0.5 0.5 0.5 0.5 49.5 49.5 49.5 49.5 0.5 25 0.5" />
          </Outer>
        )}
      </SVG>
    </Container>
  );
};
