import React, { FC, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Flex)``;

const Button = styled(Flex)<{ selected: boolean }>`
  flex: 1;
  padding-top: ${(p) => p.theme.space[2]}px;
  padding-bottom: ${(p) => p.theme.space[2]}px;
  justify-content: center;
  background-color: ${(p) => (p.selected ? p.theme.colors.grey3 : p.theme.colors.offWhite)};
  color: ${(p) => (p.selected ? p.theme.colors.white : p.theme.colors.grey3)};
  border: 1px solid;
  border-color: ${(p) => (p.selected ? 'transparent' : p.theme.colors.grey2)};
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.8;
  }
  user-select: none;
`;

type Props = {
  onSelect(selected: string[]): void;
};

const Selector: FC<Props> = ({ onSelect }) => {
  const [selected, setSelected] = useState<string[]>(['Info', 'Videos', 'Assets', 'Notes']);

  useEffect(() => {
    onSelect(selected);
  }, [selected]);

  const toggleSelection = (input: string) => {
    const exists = selected.includes(input);
    if (exists && selected.length === 4) {
      setSelected([input]);
    } else if (exists) {
      setSelected((current) => current.filter((item) => item !== input));
    } else {
      setSelected((current) => [...current, input]);
    }
  };

  return (
    <Container sx={{ borderRadius: '5px', overflow: 'hidden' }}>
      <Button selected={selected.includes('Info')} mr={[1]} onClick={() => toggleSelection('Info')}>
        <Text>Info</Text>
      </Button>
      <Button selected={selected.includes('Videos')} mr={[1]} onClick={() => toggleSelection('Videos')}>
        <Text>Videos</Text>
      </Button>
      <Button selected={selected.includes('Assets')} mr={[1]} onClick={() => toggleSelection('Assets')}>
        <Text>Assets</Text>
      </Button>
      <Button selected={selected.includes('Notes')} mr={[1]} onClick={() => toggleSelection('Notes')}>
        <Text>Notes</Text>
      </Button>
      <Button selected={false} onClick={() => setSelected(['Info', 'Videos', 'Assets', 'Notes'])}>
        <Text>Select All</Text>
      </Button>
    </Container>
  );
};

export default Selector;
