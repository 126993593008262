import { useEffect, useState } from 'react';
import { firestoreCollection } from 'util/constants';
import firebase from 'firebase/app';
import moment from 'moment';

const useProjectsListener = () => {
  const [limit, setLimit] = useState(10);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [savedLimit, setSavedLimit] = useState(
    sessionStorage.getItem('length') ?
    parseInt(sessionStorage.getItem('length')!) :
    10
  )
  const [incompleteProjects, setIncompleteProjects] = useState<IProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [sort, setSort] = useState<{ type: string; direction: 'asc' | 'desc' }>({ type: 'date', direction: 'desc' });
  const [active, setActive] = useState(0);
  const [total, setTotal] = useState(0);
  const [loadAll, setLoadAll] = useState(false);
  const [filter, setFilter] = useState<{
    budgetLow: number;
    budgetHigh: number;
    dateLow: Date;
    dateHigh: Date;
    completeState: 'complete' | 'incomplete' | 'all';
  }>({
    budgetLow: 0,
    budgetHigh: 10000000,
    dateLow: new Date(-1),
    dateHigh: new Date(2049, 11),
    completeState: 'all',
  });
  let listener: () => void = () => {
    //
  };

  function createListener() {
    listener();

    const newListener = firebase
      .firestore()
      .collection(firestoreCollection.projects)
      .orderBy(sort.type, sort.direction)
      .onSnapshot((snapshot) => {
        setProjectsLoading(true);
        const newProjects: IProject[] = [];
        snapshot.forEach((doc) => {
          newProjects.push(doc.data() as IProject);
        });
        setIncompleteProjects(newProjects.filter(project => project.complete === false));
        setProjects(newProjects);
        setProjectsLoading(false);
        setTotal(newProjects.length);

        setActive(newProjects.filter((x) => !x.complete).length);
      });
    listener = newListener;
  }

  useEffect(() => {
    createListener();
    setLimit(savedLimit)
    return () => {
      listener();
    };
  }, []);


  useEffect(() => {
    let currentCount = 0;
    const filtered = [...projects].filter((project) => {
      const greaterThanBudgetLow = project.budget >= filter.budgetLow;
      const lessThanBudgetHigh = project.budget <= filter.budgetHigh;
      const greaterThanDateLow = moment.unix(project.date).toDate().getTime() >= filter.dateLow.getTime();
      const lessThanDateHigh = moment.unix(project.date).toDate().getTime() <= filter.dateHigh.getTime();
      let matchesCompleteState = true;
      switch (filter.completeState) {
        case 'complete':
          matchesCompleteState = project.complete === true;
          break;

        case 'incomplete':
          matchesCompleteState = project.complete === false;
          break;

        case 'all':
        default:
          break;
      }
      if (
        greaterThanBudgetLow &&
        lessThanBudgetHigh &&
        greaterThanDateLow &&
        lessThanDateHigh &&
        matchesCompleteState &&
        currentCount < limit
      ) {
        currentCount++;
        return project;
      }
    });
    setFilteredProjects(filtered);
  }, [projects, filter, limit]);

  useEffect(() => {
    setLimit(savedLimit);
  }, [filter]);

  const loadMore = () => {
    setLimit(limit + 10);
  };

  const loadAllProjects = () => {
    setLoadAll(true);
  };

  useEffect(() => {
    if (loadAll) {
      setLimit(projects.length);
    } else {
      setLimit(savedLimit);
    }
  }, [loadAll, projects]);

  useEffect(() => {
    setLimit(10);
    createListener();
  }, [sort]);

  return {
    filteredProjects,
    incompleteProjects,
    projectsLoading,
    loadMore,
    loadAllProjects,
    setFilter,
    filter,
    setSort,
    sort,
    active,
    total,
    setLimit
  };
};

export default useProjectsListener;
