import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex } from 'rebass/styled-components';
import useProjectsListener from 'hooks/useProjectsListener';
import ProjectFilter from 'components/projectFilter';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { AddProject } from 'components/addProject';
import useProjects from 'hooks/useProjects';
import ProjectContainer from 'components/projectContainer';
import { push } from 'connected-react-router';
import { Spinner } from 'components/spinner';
import { DropDownMenu } from 'components/dropDownMenu';
import Slider from 'components/Slider';
import ProjectCount from 'components/ProjectCount';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dialog } from 'components/dialog';
import { IncompleteList } from 'components/incompleteList';
import { setProject } from 'store/dataSlice';
import { DropdownItemsType } from 'util/types';

const dropdownItems: DropdownItemsType[] = [
  { label: 'newest', value: 'date desc' },
  { label: 'oldest', value: 'date asc' },
  { label: 'title (a-z)', value: 'title asc' },
  { label: 'title (z-a)', value: 'title desc' },
  { label: 'client (a-z)', value: 'client asc' },
  { label: 'client (z-a)', value: 'client desc' },
  { label: 'budget low', value: 'budget asc' },
  { label: 'budget high', value: 'budget desc' },
  { label: 'agency (a-z)', value: 'agency asc' },
  { label: 'agency (z-a)', value: 'agency desc' },
];

const Home = () => {
  const {
    filteredProjects,
    projectsLoading,
    loadMore,
    loadAllProjects,
    setFilter,
    setSort,
    sort,
    active,
    total,
  } = useProjectsListener();
  const dispatch = useDispatch();
  const { addProject } = useProjects();
  const { incompleteProjects } = useProjectsListener();
  const [scale, setScale] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [searchedItems, setSearchedItems] = useState<IProject[]>([]);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const openDialog = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  const filterProjects = async (
    budgetLow: number,
    budgetHigh: number,
    dateLow: Date,
    dateHigh: Date,
    completeState: 'complete' | 'incomplete' | 'all',
  ) => {
    setFilter({
      budgetLow: budgetLow,
      budgetHigh: budgetHigh,
      dateLow: dateLow,
      dateHigh: dateHigh,
      completeState: completeState,
    });
  };

  useEffect(() => {
    const projectId = sessionStorage.getItem('lastClickedProjectId');
    if (projectId) {
      const index = filteredProjects.findIndex((project) => project.id === projectId);
      if (index !== -1 && itemRefs.current[index]) {
        itemRefs.current[index]!.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
          sessionStorage.removeItem('lastClickedProjectId');
          sessionStorage.removeItem('length');
        }, 1000);
      }
    }
  }, [filteredProjects]);
  const setSearchedProjects = (projects: IProject[]) => {
    setSearchedItems(projects);
  };

  const add = async () => {
    const id = await addProject();
    dispatch(push(`/project/${id}`));
  };

  function sortSelect(item: string) {
    let type: string = 'date';
    let direction: 'asc' | 'desc' = 'desc';

    switch (item) {
      case 'newest':
        break;
      case 'oldest':
        direction = 'asc';
        break;
      case 'title (a-z)':
        type = 'title';
        direction = 'asc';
        break;
      case 'title (z-a)':
        type = 'title';
        direction = 'desc';
        break;
      case 'client (a-z)':
        type = 'client';
        direction = 'asc';
        break;
      case 'client (z-a)':
        type = 'client';
        direction = 'desc';
        break;
      case 'budget low':
        type = 'budget';
        direction = 'asc';
        break;
      case 'budget high':
        type = 'budget';
        direction = 'desc';
        break;
      case 'agency (a-z)':
        type = 'agency';
        direction = 'asc';
        break;
      case 'agency (z-a)':
        type = 'agency';
        direction = 'desc';
        break;
      default:
        break;
    }

    if (type !== sort.type || direction !== sort.direction) {
      setSort({ type, direction });
    }
  }

  const goToProject = (project: IProject) => {
    dispatch(setProject(project));
    dispatch(push(`/project/${project.id}`));
    closeDialog();
  };

  const getSelected = (): DropdownItemsType => {
    const selectedItem = dropdownItems.find((item) => item.value === `${sort.type} ${sort.direction}`);
    return selectedItem || dropdownItems[0]; // Default to the first item if not found
  };

  return (
    <Box p={[2]} minHeight={'101vh'}>
      <ProjectFilter setFilter={filterProjects} setSearchedProjects={setSearchedProjects} />
      <Box mb={[2]} />
      <Flex justifyContent="space-between" alignItems="center">
        <Slider onUpdate={setScale} min={0.5} max={1} />
        <ProjectCount active={active} total={total} showIncomplete={openDialog} />
        <DropDownMenu
          selected={getSelected()}
          menuItems={dropdownItems}
          onSelect={(item) => sortSelect(item.value)}
          width={'200px'}
        />
      </Flex>
      <Box mb={[2]} />
      <InfiniteScroll
        dataLength={filteredProjects.length}
        next={() => {
          loadMore();
        }}
        hasMore={true}
        loader={<></>}
        scrollThreshold={0.9}
      >
        <Box
          sx={{
            display: 'grid',
            gridGap: [2],
            gridTemplateColumns: `repeat(auto-fill, minmax(${300 * scale}px, 1fr))`,
          }}
        >
          {projectsLoading ? (
            <Flex justifyContent="center" alignItems="center" minHeight={300}>
              <Spinner altStyle />
            </Flex>
          ) : (
            <>
              <AddProject add={add} loading={false} scale={scale} />
              {showDialog && (
                <Dialog title="Incomplete Projects" exit={closeDialog}>
                  <IncompleteList incompleteProjects={incompleteProjects} gotoProject={goToProject} />
                </Dialog>
              )}
              {searchedItems.length > 0
                ? searchedItems.map((project) => <ProjectContainer key={project.id} project={project} scale={scale} />)
                : filteredProjects.map((project, index) => (
                    <div ref={(el) => (itemRefs.current[index] = el)}>
                      <ProjectContainer
                        key={project.id}
                        project={project}
                        scale={scale}
                        setProjectsLength={() => {
                          sessionStorage.setItem('length', filteredProjects.length.toString());
                        }}
                      />
                    </div>
                  ))}
            </>
          )}
        </Box>
      </InfiniteScroll>
      {!projectsLoading && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 4,
            right: 4,
          }}
        >
          <Button onClick={loadAllProjects}>Load All</Button>
        </Box>
      )}
    </Box>
  );
};

export default Home;
