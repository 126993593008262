import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Slider as slider } from '@rebass/forms/styled-components';

const Container = styled(slider)`
  width: 100px;
  border: none;
  color: white;
  :focus {
    color: ${(p) => p.theme.colors.primary};
  }
`;

type Props = {
  onUpdate(value: number): void;
  min: number;
  max: number;
};

const Slider: FC<Props> = ({ onUpdate, min, max }: Props) => {
  const [value, setValue] = useState(1);

  const onChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    onUpdate(value);
  }, [value]);

  return (
    <Box>
      <Container step="0.01" min={min} max={max} value={value} bg="grey2" onChange={onChange} />
    </Box>
  );
};

export default Slider;
