import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from './icon';
import { DropItems } from './dropItems';
import { Box, Text } from 'rebass/styled-components';
import { DropdownItemsType } from 'util/types';

const Container = styled(Box)<{ width: string }>`
  min-width: ${(p) => p.width};
  width: ${(p) => p.width};
  position: relative;
`;

const TextComponent = styled(Text)<{ colour: 'grey' | 'white'; highlighted: boolean }>`
  flex-grow: 1;
  user-select: none;
  color: ${(p) =>
    p.highlighted ? p.theme.colors.green : p.colour === 'grey' ? p.theme.colors.grey3 : p.theme.colors.white};
`;

const Top = styled.div<{ colour: 'grey' | 'white' }>`
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid ${(p) => (p.colour === 'grey' ? p.theme.colors.grey3 : p.theme.colors.white)};
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :hover {
    border: 1px solid ${(p) => (p.colour === 'grey' ? p.theme.colors.primary : p.theme.colors.secondary)};
  }
  :hover ${TextComponent} {
    color: ${(p) => (p.colour === 'grey' ? p.theme.colors.primary : p.theme.colors.secondary)};
  }
`;

const Options = styled.div`
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const Select = styled.div<{ colour: 'grey' | 'white' }>`
  width: 100%;
  position: relative;
  display: inline-block;
`;

type Props = {
  selected: DropdownItemsType;
  menuItems: DropdownItemsType[];
  onSelect(item: DropdownItemsType): void;
  width: string;
  style?: 'grey' | 'white';
};

export const DropDownMenu: React.FC<Props> = ({ selected, menuItems, onSelect, width, style = 'grey' }) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const itemClick = (item: DropdownItemsType) => {
    onSelect(item);
    setOpen(false);
  };

  return (
    <Container width={width}>
      <Select colour={style}>
        <Top onClick={() => setOpen(!open)} colour={style} ref={containerRef}>
          <TextComponent highlighted={selected.value === 'master'} colour={style}>
            {selected.label}
          </TextComponent>
          <Icon
            style={'down'}
            onClick={() => {}}
            colour={style === 'grey' ? IconColour.grey : IconColour.secondary}
            noBox={true}
          />
        </Top>
        {open && (
          <Options>
            <DropItems
              menuItems={menuItems}
              onSelect={itemClick}
              close={() => setOpen(false)}
              containerRef={containerRef.current! as HTMLDivElement}
            />
          </Options>
        )}
      </Select>
    </Container>
  );
};
