import React, { FC, useState, useEffect } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled(Box)``;

const Select = styled(Box)<{ altStyle: boolean }>`
  cursor: pointer;
  border-color: ${(p) => p.theme.colors.grey2};
  color: ${(p) => (p.altStyle ? p.theme.colors.white : p.theme.colors.grey3)};
  :focus {
    outline-color: ${(p) => (p.altStyle ? p.theme.colors.secondary : p.theme.colors.primary)};
  }
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: none;
  appearance: none;
  appearance: none;
  background-position: right 40px;
`;

const Option = styled(Box)`
  border: none;
  color: ${(p) => p.theme.colors.grey3};
`;

type Props = {
  initialDate: Date;
  updated(date: Date): void;
  altStyle?: boolean;
  allowUpdate?: boolean;
};

const DateSelector: FC<Props> = ({ initialDate, updated, altStyle = false, allowUpdate }) => {
  const [day, setDay] = useState(parseInt(moment(initialDate).format('DD')));
  const [month, setMonth] = useState(moment(initialDate).format('MMM').toUpperCase());
  const [year, setYear] = useState(parseInt(moment(initialDate).format('YYYY')));

  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  useEffect(() => {
    const newDate = moment(`${day}-${month}-${year}`).toDate();
    updated(newDate);
  }, [day, month, year]);

  useEffect(() => {
    if (allowUpdate) {
      setDay(parseInt(moment(initialDate).format('DD')));
      setMonth(moment(initialDate).format('MMM').toUpperCase());
      setYear(parseInt(moment(initialDate).format('YYYY')));
    }
  }, [initialDate]);

  return (
    <Container>
      <Select
        as="select"
        style={{ width: '50px' }}
        onChange={(input: any) => setDay(input.target.value)}
        altStyle={altStyle}
      >
        {Array(31)
          .fill(0)
          .map((value, index) => (
            <Option key={`day-${index + 1}`} as="option" selected={index + 1 === day} value={index + 1}>
              {index + 1}
            </Option>
          ))}
      </Select>
      <Text color={altStyle ? 'white' : 'grey2'} sx={{ display: 'inline-block' }}>
        &nbsp;-&nbsp;
      </Text>
      <Select as="select" onChange={(input: any) => setMonth(input.target.value)} altStyle={altStyle}>
        {months.map((value) => (
          <Option key={`month-${value}`} as="option" selected={value === month} value={value}>
            {value}
          </Option>
        ))}
      </Select>
      <Text color={altStyle ? 'white' : 'grey2'} sx={{ display: 'inline-block' }}>
        &nbsp;-&nbsp;
      </Text>
      <Select as="select" onChange={(input: any) => setYear(input.target.value)} altStyle={altStyle}>
        {Array(51)
          .fill(0)
          .map((value, index) => (
            <Option key={`year-${index + 2000}`} as="option" selected={index + 2000 === year} value={index + 2000}>
              {index + 2000}
            </Option>
          ))}
      </Select>
    </Container>
  );
};

export default DateSelector;
