import React from 'react';
import styled from 'styled-components';
import { videoSort } from '../../util/constants';
import { SortTextButton } from '../sortTextButton';
import { Box } from 'rebass';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const Bar = styled.div`
  padding: ${(p) => p.theme.space[2]}px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.greenDark};
`;

const InputContainer = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? '150px' : p.width)};
  box-sizing: border-box;
`;

const Empty = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => p.width};
`;

const Divider = styled.div`
  width: ${(p) => p.theme.space[2]}px;
  min-width: ${(p) => p.theme.space[2]}px;
`;

type Props = {
  currentSort: { sortType: videoSort; direction: 'asc' | 'desc' };
  update(sortType: videoSort, direction: 'asc' | 'desc'): void;
};

export const VideoSortBar: React.FC<Props> = ({ currentSort, update }) => {
  const updateSort = (sortType: videoSort, direction: 'desc' | 'asc') => {
    update(sortType, direction);
  };
  const checkState = (sortType: videoSort): 'asc' | 'desc' | undefined => {
    return currentSort.sortType === sortType ? currentSort.direction : undefined;
  };
  return (
    <Container>
      <Bar>
        <Empty width={'50px'} />
        <SortTextButton
          text={'Filename'}
          onClick={(direction) => updateSort(videoSort.filename, direction)}
          state={checkState(videoSort.filename)}
        />
        <Box flex={1} />
        <SortTextButton
          text={'Ext'}
          onClick={(direction) => updateSort(videoSort.ext, direction)}
          state={checkState(videoSort.ext)}
        />
        <Empty width={'7px'} />
        <SortTextButton
          text={'Size'}
          onClick={(direction) => updateSort(videoSort.size, direction)}
          state={checkState(videoSort.size)}
        />
        <Empty width={'96px'} />
        <SortTextButton
          text={'Cat'}
          onClick={(direction) => updateSort(videoSort.category, direction)}
          state={checkState(videoSort.category)}
        />
        <Empty width={'7px'} />
        <SortTextButton
          text={'Date'}
          onClick={(direction) => updateSort(videoSort.date, direction)}
          state={checkState(videoSort.date)}
        />
        <Empty width={'92px'} />
      </Bar>
    </Container>
  );
};
