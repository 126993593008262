import React, { FC, useRef, useEffect, useState } from 'react';
import { Card, Image, Flex, Box } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';
import { variant } from 'util/constants';
import { Icon } from './icon';
import useProjects from 'hooks/useProjects';
import FileDropZone from './fileDropzone';
import { Spinner } from './spinner';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { setProject } from 'store/dataSlice';
import useFeature from 'hooks/useFeature';

type Props = {
  project: IProject;
  scale: number;
  setProjectsLength?: () => void;
};

const ProjectContainer: FC<Props> = ({ project, scale, setProjectsLength }: Props) => {
  const dispatch = useDispatch();
  const { removeProject, updateProject, duplicateProject } = useProjects();
  const [title, setTitle] = useState(project.title);
  const [jobNumber, setJobNumber] = useState(project.jobNumber);
  const [client, setClient] = useState(project.client);
  const [imageLoading, setImageLoading] = useState(false);
  const { uploadFeature } = useFeature(project.id);

  const onBlur = () => {
    if (title !== project.title || jobNumber !== project.jobNumber || client !== project.client) {
      updateProject(project.id, { title, jobNumber, client });
    }
  };

  useEffect(() => {
    if (project.title !== title) {
      setTitle(project.title);
    }
    if (project.jobNumber !== jobNumber) {
      setJobNumber(project.jobNumber);
    }
    if (project.client !== client) {
      setClient(project.client);
    }
  }, [project]);

  const upload = async (file: File) => {
    uploadFeature(file, (task) => {
      task.on(
        'state_changed',
        (snapshot) => {
          setImageLoading(true);
        },
        (error) => {
          setImageLoading(false);
        },
        () => {
          setImageLoading(false);
        },
      );
    });
  };

  const confirmDuplicateProject = (project: IProject) => {
    if(window.confirm('Are you sure you want to duplicate this project?')) {
      duplicateProject(project);
    }
  }

  const goToProject = () => {
    dispatch(setProject(project));
    if (setProjectsLength) {
      setProjectsLength();
    }
    sessionStorage.setItem('lastClickedProjectId', project.id);
    dispatch(push(`/project/${project.id}`));
  };

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: 'grey2',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: 'main',
      }}
    >
      <Flex
        variant={variant.box.title}
        bg={project.complete ? 'green' : 'primary'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        px={[2]}
      >
        <Icon
          tooltip={project.complete ? 'Set Incomplete' : 'Set Complete'}
          style={project.complete ? 'cross' : 'tick'}
          onClick={() => updateProject(project.id, { complete: !project.complete })}
        />
        <Box mr={[2]} />
        <Icon tooltip={'Delete Project'} style={'delete'} onClick={() => removeProject(project.id)} />
        <Box mr={[2]} />
        <Icon tooltip={'Duplicate Project'} style={'folder'} onClick={() => confirmDuplicateProject(project)} />
      </Flex>
      <Flex
        bg={'primary'}
        width={'100%'}
        height={200 * scale}
        overflow={'hidden'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          cursor: 'pointer',
          outline: 'none',
          ':focus': { outline: 'none' },
          ':active': { filter: 'brightness(0.8)' },
        }}
        onClick={goToProject}
      >
        <FileDropZone upload={upload}>
          {imageLoading && (
            <Flex
              sx={{ position: 'absolute', width: '100%', height: '100%' }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Spinner />
            </Flex>
          )}
          <Box
            opacity={imageLoading ? 0.25 : 1}
            height={'100%'}
            width={'100%'}
            sx={{
              ':hover': { filter: 'brightness(1.1)' },
              backgroundImage: `url('${project.featureURL}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </FileDropZone>
      </Flex>
      <form onBlur={onBlur}>
        <Input
          type="text"
          placeholder={'Add project title'}
          value={title}
          onChange={(input: any) => setTitle(input.target.value)}
        />
        <Box variant={variant.box.line} />
        <Input
          type="text"
          placeholder={'Add project number'}
          value={jobNumber}
          onChange={(input: any) => setJobNumber(input.target.value)}
        />
        <Box variant={variant.box.line} />
        <Input
          type="text"
          placeholder={'Add client'}
          value={client}
          onChange={(input: any) => setClient(input.target.value)}
        />
      </form>
    </Card>
  );
};

export default ProjectContainer;
