import { useDispatch } from 'react-redux';
import { setTooltip as setTT, toggleTooltip } from 'store/utilSlice';

const useTooltip = () => {
  const dispatch = useDispatch();

  const setTooltip = (tooltip: string) => {
    dispatch(setTT(tooltip));
    dispatch(toggleTooltip());
  };

  return {
    setTooltip,
  };
};

export default useTooltip;
