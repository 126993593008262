import React from 'react';
import styled from 'styled-components';
import { Icon } from './icon';
import { Text, Box } from 'rebass/styled-components';
import { LoadingBar } from './loadingBar';

const Bar = styled(Box)`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p) => p.theme.space[3]}px;
  box-sizing: border-box;
`;

const TitleText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const Filler = styled(Box)`
  flex-grow: 1;
`;

type Props = {
  addRecord(): void;
  loading: boolean;
};

const RecordsHeader: React.FC<Props> = ({ addRecord, loading }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey2',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: 'main',
      }}
    >
      <Bar bg={'primary'}>
        <TitleText>{'Videos & Audio'}</TitleText>
        <Filler />
        <Icon tooltip={'Add Record'} style={'add'} onClick={addRecord} />
      </Bar>
      <LoadingBar loading={loading} />
    </Box>
  );
};

export default RecordsHeader;
