import { useEffect, useState } from 'react';
import { firestoreCollection, assetSort } from 'util/constants';
import firebase from 'firebase/app';

const useAssetsListener = (projectID: string, sort: assetSort, direction: 'asc' | 'desc') => {
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [assets, setAssets] = useState<IAsset[]>([]);

  useEffect(() => {
    const listener = firebase
      .firestore()
      .collection(firestoreCollection.assets)
      .where('projectID', '==', projectID)
      .orderBy(sort, direction)
      .onSnapshot((snapshot) => {
        setAssetsLoading(true);
        let Assets: IAsset[] = [];
        snapshot.forEach((doc) => {
          Assets.push(doc.data() as IAsset);
        });
        setAssets(Assets);
        setAssetsLoading(false);
      });
    return () => {
      listener();
    };
  }, []);

  return {
    assets,
    assetsLoading,
  };
};

export default useAssetsListener;
