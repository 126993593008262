import { useEffect, useState } from 'react';
import { firestoreCollection, videoSort } from 'util/constants';
import firebase from 'firebase/app';
import path from 'path';

const useVideosListener = (recordID: string) => {
  const [videosLoading, setVideosLoading] = useState(true);
  const [videos, setVideos] = useState<IVideo[]>([]);
  const [sort, updateSort] = useState<{ sortType: videoSort; direction: 'asc' | 'desc' }>({
    sortType: videoSort.date,
    direction: 'asc',
  });

  const sortVideos = (videos: IVideo[]): IVideo[] => {
    let newVideos = [...videos];
    switch (sort.sortType) {
      case videoSort.filename:
        newVideos.sort((a, b) =>
          a.id < b.id ? (sort.direction === 'asc' ? 1 : -1) : sort.direction === 'asc' ? -1 : 1,
        );
        break;

      case videoSort.ext:
        newVideos.sort((a, b) =>
          path.extname(a.id) < path.extname(b.id)
            ? sort.direction === 'asc'
              ? 1
              : -1
            : sort.direction === 'asc'
            ? -1
            : 1,
        );
        break;

      case videoSort.date:
        newVideos.sort((a, b) =>
          a.date < b.date ? (sort.direction === 'asc' ? 1 : -1) : sort.direction === 'asc' ? -1 : 1,
        );
        break;

      case videoSort.size:
        newVideos.sort((a, b) =>
          a.size < b.size ? (sort.direction === 'asc' ? 1 : -1) : sort.direction === 'asc' ? -1 : 1,
        );
        break;
      
      case videoSort.category:
        newVideos.sort((a, b) =>
          a.category < b.category ? (sort.direction === 'asc' ? 1 : -1) : sort.direction === 'asc' ? -1 : 1
        );
        break;
  

      default:
        break;
    }
    return newVideos;
  };

  useEffect(() => {
    const listener = firebase
      .firestore()
      .collection(firestoreCollection.videos)
      .where('recordID', '==', recordID)
      .onSnapshot((snapshot) => {
        setVideosLoading(true);
        let videos: IVideo[] = [];
        snapshot.forEach((doc) => {
          videos.push(doc.data() as IVideo);
        });
        setVideos(sortVideos(videos));
        setVideosLoading(false);
      });
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    setVideos((current) => sortVideos(current));
  }, [sort]);

  return {
    videos,
    videosLoading,
    updateSort,
  };
};

export default useVideosListener;
