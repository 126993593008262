import firebase from 'firebase/app';
import { useState, useEffect } from 'react';
import { deleteModal } from 'util/modal';

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loadingAuthState, setLoadingAuthState] = useState(true); // New state

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          setIsAdmin(userDoc.data()?.isAdmin || false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoadingAuthState(false); // Auth state has been checked
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      return true;
    } catch (error) {
      console.error('Sign in error:', error); // Debug log
      return false;
    }
  };

  const signOut = async () => {
    const confirm = await deleteModal('Are you sure you want to log out?');
    if (confirm) {
      firebase.auth().signOut();
    }
  };

  const signUp = async (email: string, password: string, isAdmin: boolean = false) => {
    try {
      setLoading(true);
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (!userCredential || !userCredential.user) return;

      const userId = userCredential.user.uid;

      await firebase.firestore().collection('users').doc(userId).set({
        email,
        isAdmin: isAdmin,  // Store isAdmin as a boolean
      });

      alert('User added successfully');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    signIn,
    signOut,
    signUp,
    user,
    isAdmin,
    loadingAuthState, // Return the loadingAuthState
    loading,
  };
};

export default useAuth;
