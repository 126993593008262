import { useFirestore, useFirebase } from 'react-redux-firebase';
import { uniqueFileName } from 'util/index';
import { firestoreCollection, storageBucketRef, storageBucket, docType } from 'util/constants';
import { deleteDocModal } from 'util/modal';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useProject = (id: string) => {
  const firestore = useFirestore();
  const storage = useFirebase().storage();
  const dispatch = useDispatch();

  const removeProject = async (id: string) => {
    const confirm = await deleteDocModal(docType.project);
    if (confirm) {
      await firestore.collection(firestoreCollection.projects).doc(id).delete();
      dispatch(push('/'));
    }
  };

  const updateProject = (id: string, project: Partial<IProject>) => {
    firestore.collection(firestoreCollection.projects).doc(id).update(project);
  };

  return {
    removeProject,
    updateProject,
  };
};

export default useProject;
