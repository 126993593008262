import algoliaSearch from 'algoliasearch';

const useAlgolia = () => {
  const algoliaClient = algoliaSearch('NYNZS3O250', '6e0ab3c486788f4e358b754ae181e643');
  const projectsIndex = algoliaClient.initIndex('projects');
  const recordsIndex = algoliaClient.initIndex('records');
  const notesIndex = algoliaClient.initIndex('notes');
  const videosIndex = algoliaClient.initIndex('videos');
  const assetsIndex = algoliaClient.initIndex('assets');

  const searchString = async (search: string): Promise<any[]> => {
    const projects = await projectsIndex.search(search);
    const records = await recordsIndex.search(search);
    const notes = await notesIndex.search(search);
    const videos = await videosIndex.search(search);
    const assets = await assetsIndex.search(search);

    const searched = await algoliaClient.multipleQueries([
      { indexName: 'projects', query: search },
      { indexName: 'records', query: search },
      { indexName: 'notes', query: search },
      { indexName: 'videos', query: search },
      { indexName: 'assets', query: search },
    ]);

    let documents = [];
    for (const results of searched.results) {
      for (const doc of results.hits) {
        documents.push(doc);
      }
    }

    let result: any[] = projects.hits;

    let ProjectIDs = [];
    for (const hit of [...records.hits, ...notes.hits, ...assets.hits]) {
      let projectExists = result.includes((x: any) => x.id === (hit as any).id);
      if (!projectExists) {
        ProjectIDs.push((hit as any).projectID);
      }
    }
    const newProjectIDs = await projectsIndex.getObjects(ProjectIDs);
    result = [...result, ...newProjectIDs.results];

    let videoProjectIDs = [];
    let videoRecordIDs = [];
    for (const hit of videos.hits) {
      videoRecordIDs.push((hit as any).recordID);
    }
    let videoRecords = await recordsIndex.getObjects(videoRecordIDs);
    for (const hit of videoRecords.results) {
      let projectExists = result.includes((x: any) => x.id === (hit as any).id);
      if (!projectExists) {
        videoProjectIDs.push((hit as any).projectID);
      }
    }
    const newVideoProjectIDs = await projectsIndex.getObjects(videoProjectIDs);
    result = [...result, ...newVideoProjectIDs.results];

    const resultMinusNull = result.filter((element) => {
      return !!element;
    });

    const finalResult = Array.from(new Set(resultMinusNull.map((a) => a.id))).map((id) => {
      return resultMinusNull.find((a) => a.id === id);
    });

    return finalResult.sort((a, b) => b.date - a.date);
  };

  return { searchString };
};

export default useAlgolia;
