const formVariants = {
  input: {
    color: 'greyDark',
    '::placeholder': {
      opacity: 0.3,
    },
    border: '1px solid white',
    ':focus': {
      border: `1px solid`,
      borderColor: 'secondary',
      '::placeholder': {
        color: 'primary',
      },
    },
    outline: 'none',
  },
  inputOnPrimary: {
    color: 'white',
    '::placeholder': {
      color: 'white',
      opacity: 0.5,
    },
    border: '0.5px solid white',
    ':focus': {
      border: `0.5px solid`,
      borderColor: 'secondary',
    },
    outline: 'none',
  },
  inputField: {
    px: 0,
    pt: 0,
    pb: [1],
    color: 'grey3',
    '::placeholder': {
      opacity: 0.3,
    },
    border: 'none',
    borderBottom: '0.5px solid',
    borderColor: 'grey2',
    ':focus': {
      border: 'none',
      borderBottom: `0.5px solid`,
      borderColor: 'secondary',
      '::placeholder': {
        color: 'primary',
      },
    },
    outline: 'none',
  },
  inputFieldNoLine: {
    px: 0,
    pt: 0,
    pb: 0,
    color: 'greyDark',
    '::placeholder': {
      opacity: 0.3,
    },
    border: 'none',
    ':focus': {
      border: 'none',
      '::placeholder': {
        color: 'primary',
      },
    },
    outline: 'none',
  },
};

export default formVariants;
