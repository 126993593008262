import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from './icon';
import { TextInput } from './input/textInput';
import { DateInput } from './input/dateInput';
import { SearchList } from './searchList';
import { NumberInput } from './input/numberInput';
import { ResetButton } from './resetButton';
import { TripleSwitch } from './tripleSwitch';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import useAlgolia from 'hooks/useAlgolia';
import DateSelector from './input/dateSelector';

const Container = styled.div`
  width: 100%;
`;

const Bar = styled.div`
  flex-grow: 1;
  height: 50px;
  background-color: ${(p) => p.theme.colors.green};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  box-sizing: border-box;
  padding-right: 5px;
`;

const FilterComponent = styled.div`
  flex-grow: 1;
  background-color: ${(p) => p.theme.colors.greenDark};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  overflow-x: scroll;
  white-space: nowrap;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
  padding-bottom: 3px;
  padding-right: 5px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding-left: ${(p) => p.theme.space[1]}px;
  padding-right: ${(p) => p.theme.space[1]}px;
  flex-grow: 1;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
`

const Padding = styled.div`
  margin: 5px 0 2px 5px;
`;

type Props = {
  setFilter(
    budgetLow: number,
    budgetHigh: number,
    dateLow: Date,
    dateHigh: Date,
    completeState: 'complete' | 'incomplete' | 'all',
  ): void;
  setSearchedProjects(items: IProject[]): void;
};

const ProjectFilter: React.FC<Props> = ({ setFilter, setSearchedProjects }) => {
  const dispatch = useDispatch();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchItems, setSearchItems] = useState<(IProject | IRecord | IVideo | INote)[]>([]);
  const [completeState, setCompleteState] = useState<'complete' | 'incomplete' | 'all'>('all');
  const [searchInputValue, setSearchInputValue] = useState('')

  const [budgetLow, setBudgetLow] = useState(0);
  const [budgetHigh, setBudgetHigh] = useState(10000000);
  const [dateLow, setDateLow] = useState(new Date(-1));
  const [dateHigh, setDateHigh] = useState(new Date(2049, 11));

  const [filterMenu, toggleFilterMenu] = useState(false);

  const { searchString } = useAlgolia();

  const search = async (input: string) => {
    if (input !== '') {
      const results = await searchString(input);
      setSearchItems(results);
    } else {
      setSearchItems([]);
      setSearchedProjects([]);
    }
  };

  const setSearchValueAndSearch = async (input: string) => {
    if (input !== '') {
      const results = await searchString(input);
      setSearchItems([]);
      setSearchedProjects(results);
    }
  }

  const setSearch = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setSearchVisible(false);
      }, 300);
    } else {
      setSearchVisible(true);
    }
  };

  const searchClick = async (id: string) => {
    dispatch(push(`/project/${id}`));
  };

  useEffect(() => {
    setFilter(budgetLow, budgetHigh, dateLow, dateHigh, completeState);
  }, [budgetLow, budgetHigh, dateLow, dateHigh, completeState]);

  const resetFilters = () => {
    setCompleteState('all');
    setBudgetLow(0);
    setBudgetHigh(10000000);
    setDateLow(new Date(-1));
    setDateHigh(new Date(2049, 11));
  };

  return (
    <Container>
      <Bar>
        <SearchContainer>
          <TextInput
            value={searchInputValue}
            setInput={setSearchInputValue}
            name={'projectSearch'}
            placeholder={'Search projects...'}
            update={search}
            style={'secondary'}
            isActive={setSearch}
            continuousUpdate={true}
            onEnterPress={setSearchValueAndSearch}
          />
          {searchVisible && <SearchList key={'Search List'} items={searchItems as IProject[]} click={searchClick} />}
          <CloseIconContainer>
          <Icon style="cross" onClick={() => setSearchInputValue('')} />
          </CloseIconContainer>
        </SearchContainer>
        <Icon
          tooltip={'Filter Projects'}
          height={'40px'}
          onClick={() => toggleFilterMenu(!filterMenu)}
          style={'filter'}
          colour={IconColour.primary}
        />
      </Bar>
      {filterMenu && (
        <FilterComponent>
          <Padding>
            <TripleSwitch stateChange={setCompleteState} state={completeState} />
          </Padding>
          <Padding>{completeState}</Padding>
          <Padding>projects from</Padding>
          <Padding>
            <DateSelector initialDate={dateLow} updated={setDateLow} altStyle allowUpdate />
          </Padding>
          <Padding>to</Padding>
          <Padding>
            <DateSelector initialDate={dateHigh} updated={setDateHigh} altStyle allowUpdate />
          </Padding>
          <Padding>with budget of </Padding>
          <Padding>
            $&nbsp;
            <NumberInput
              value={budgetLow}
              name={'budgetmin'}
              update={setBudgetLow}
              style={'secondary'}
              width={'80px'}
            />
          </Padding>
          <Padding>to</Padding>
          <Padding>
            $&nbsp;
            <NumberInput
              value={budgetHigh}
              name={'budgetmax'}
              update={setBudgetHigh}
              style={'secondary'}
              width={'100px'}
            />
          </Padding>
          <Padding>
            <ResetButton text={'reset'} onClick={resetFilters} />
          </Padding>
        </FilterComponent>
      )}
    </Container>
  );
};

export default ProjectFilter;
