import React, { FC } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Box)``;

type Props = {
  onClick(): void;
};

const AddRecord: FC<Props> = ({ onClick }) => {
  return (
    <Flex
      height={50}
      width="100%"
      bg="offWhite"
      onClick={onClick}
      sx={{
        outline: '1px dashed',
        outlineColor: 'grey2',
        outlineOffset: -5,
        borderRadius: '5px',
        overflow: 'hidden',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'grey2',
          outlineColor: 'white',
          color: 'white',
        },
        ':active': {
          backgroundColor: 'grey3',
          outlineColor: 'white',
          color: 'white',
        },
      }}
      color="grey2"
      justifyContent="center"
      alignItems="center"
    >
      <Text sx={{ userSelect: 'none' }}>Add Record +</Text>
    </Flex>
  );
};

export default AddRecord;
