import React from 'react';
import styled from 'styled-components';

const Polygon = styled.polygon`
  fill: none;
  stroke-miterlimit: 10;
  fill-rule: evenodd;
`;

type Props = {};

export const Move: React.FC<Props> = () => {
  return (
    <g>
      <Polygon points="25 10 30 15 35 10 25 0 15 10 20 15 25 10" />
      <Polygon points="25 40 30 35 35 40 25 50 15 40 20 35 25 40" />
      <Polygon points="10 25 15 20 10 15 0 25 10 35 15 30 10 25" />
      <Polygon points="40 25 35 20 40 15 50 25 40 35 35 30 40 25" />
    </g>
  );
};
