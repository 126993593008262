import React, { useEffect, useState, FC, useRef } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';
import { variant } from 'util/constants';
import moment from 'moment';
import { Icon, IconColour } from './icon';

type Props = {
  initialInput: string | number;
  onChange(input: any): void;
  type: string;
  placeholder: string;
  fieldName: string;
  onEnter?: boolean;
  tooltip?: string;
  disabled?: boolean;
  isWhite?: boolean;
  hasLine?: boolean;
};

const InputField: FC<Props> = ({
  onChange,
  initialInput,
  type,
  placeholder,
  fieldName,
  onEnter = false,
  tooltip = '',
  disabled = false,
  isWhite = false,
  hasLine = true,
}) => {
  const [input, setInput] = useState<string | number>(initialInput);
  const [intialised, setInitialised] = useState(false);

  useEffect(() => {
    if (intialised) {
      if (!onEnter) onChange(input);
    } else {
      setInitialised(true);
    }
  }, [input]);

  const tagEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && onEnter) {
      sendChange();
    }
  };

  const sendChange = () => {
    setInput('');
    onChange(input);
  };

  return (
    <Box>
      {hasLine && (
        <Text fontSize={[2]} fontWeight={300} color={'grey2'}>
          {fieldName}
        </Text>
      )}
      <Flex>
        <Input
          type={type}
          placeholder={placeholder}
          value={input}
          onChange={(input: any) => setInput(input.target.value)}
          variant={hasLine ? variant.forms.inputField : variant.forms.inputFieldNoLine}
          onKeyUp={tagEnter}
          disabled={disabled}
          color={isWhite ? 'white' : 'grey3'}
        />
        {onEnter && (
          <>
            <Box mr={[2]} />
            <Icon style={'add'} onClick={() => sendChange()} colour={IconColour.grey} tooltip={tooltip} />
          </>
        )}
      </Flex>
    </Box>
  );
};

export default InputField;
