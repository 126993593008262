import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { firestoreCollection } from 'util/constants';
import { DefaultsType } from '../views/admin/types';

const useDefaultFieldsListener = () => {
  const [defaults, setDefaults] = useState<DefaultsType>();

  useEffect(() => {
    try {
      firebase
        .firestore()
        .collection(firestoreCollection.projectDefaults)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs[0].data();
          setDefaults(data as DefaultsType);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return { defaults };
};

export default useDefaultFieldsListener;
