import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
  uploads: IUpload[];
};

const initialState: state = {
  uploads: [],
};

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUpload(state, action: PayloadAction<IUpload>) {
      let index = state.uploads.findIndex((x) => x.id === action.payload.id);
      let uploads = [...state.uploads];
      if (index === -1) {
        uploads.push(action.payload);
      } else {
        if (action.payload.progress === 100) {
          uploads.splice(index, 1);
        } else {
          uploads[index] = action.payload;
        }
      }

      state.uploads = uploads;
    },
    resetUploads(state) {
      state.uploads = [];
    },
  },
});

export const { setUpload, resetUploads } = slice.actions;

export default slice.reducer;
