import React from 'react';
import styled from 'styled-components';

const Line = styled.line`
  fill: none;
  stroke-miterlimit: 10;
`;

const Circle = styled.circle`
  fill: none;
  stroke-miterlimit: 10;
`;

type Props = {};

export const Filter: React.FC<Props> = () => {
  return (
    <g>
      <Line x1="36.3" y1="25" x2="38.5" y2="25" />
      <Line x1="11.5" y1="25" x2="28.7" y2="25" />
      <Line x1="21.3" y1="16" x2="38.5" y2="16" />
      <Line x1="11.5" y1="16" x2="13.8" y2="16" />
      <Line x1="28.7" y1="34" x2="38.5" y2="34" />
      <Line x1="11.5" y1="34" x2="21.2" y2="34" />
      <Circle cx="25" cy="34" r="3.7" />
      <Circle cx="32.5" cy="25" r="3.7" />
      <Circle cx="17.5" cy="16" r="3.7" />
    </g>
  );
};
