import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Text, Flex } from 'rebass/styled-components';
import useAssetsListener from 'hooks/useAssetsListener';
import AssetContainer from './assetContainer';
import { LoadingBar } from 'components/loadingBar';
import useUtil from 'hooks/useUtil';

const Bar = styled(Box)`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const Container = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  :nth-child(odd) ${Bar} {
    background-color: ${(p) => p.theme.colors.green};
  }
  :nth-child(even) ${Bar} {
    background-color: ${(p) => p.theme.colors.green};
  }
`;

type Props = {
  projectID: string;
};

const Assets: React.FC<Props> = ({ projectID }) => {
  const [uploading, setUploading] = useState(false);
  const { currentSort } = useUtil();
  const { assets } = useAssetsListener(projectID, currentSort.sortType, currentSort.direction);

  return (
    <Container
      bg="offWhite"
      sx={{
        border: '1px solid',
        borderColor: 'grey2',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: 'main',
      }}
    >
      <Flex bg={'primary'} height={50} alignItems="center" px={[3]}>
        <Text color="white">Assets</Text>
      </Flex>
      <LoadingBar loading={uploading} height={'0px'} />
      <Box height={400} bg="offWhite">
        <AssetContainer files={assets} projectID={projectID} isUploading={setUploading} />
      </Box>
    </Container>
  );
};

export default Assets;
