import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from './icon';
import moment from 'moment';
import { TextAreaComponent } from './textArea';
import { LoadingBar } from './loadingBar';
import { Text, Box, Flex, Button } from 'rebass/styled-components';
import InputField from './input';
import NoteForm from './noteForm';
import { placeholderNote } from 'util/placeholder';
import { deleteModal } from 'util/modal';
import { docType } from 'util/constants';

const Container = styled(Box)<{ loading: boolean }>`
  width: 100%;
  min-height: 400px;
  height: 409px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: ${(p) => (p.loading ? p.theme.colors.grey1 : p.theme.colors.white)};
`;
const TitleText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const Filler = styled.div`
  flex-grow: 1;
`;

const Left = styled(Box)`
  width: 200px;
  height: 350px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.grey1};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.colors.grey1};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.grey2};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.colors.grey3};
  }
`;

const Right = styled(Box)`
  flex-grow: 1;
  box-sizing: border-box;
`;

const TabTitle = styled(Text)`
  flex-grow: 1;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TabDate = styled(Text)`
  min-width: 80px;
  text-align: right;
  user-select: none;
`;

const Tab = styled(Flex)<{ selected: boolean }>`
  height: 50px;
  width: 100%;
  align-items: center;
  border-bottom: 0.5px solid ${(p) => p.theme.colors.grey2};
  cursor: ${(p) => (p.selected ? 'default' : 'pointer')};
  :nth-child(odd) {
    background-color: ${(p) => (p.selected ? p.theme.colors.green : '#f9f9f9')};
  }
  :nth-child(even) {
    background-color: ${(p) => (p.selected ? p.theme.colors.green : p.theme.colors.white)};
  }
  :hover {
    background-color: ${(p) => (p.selected ? p.theme.colors.greenDark : p.theme.colors.grey2)};
  }
  :active {
    background-color: ${(p) => (p.selected ? p.theme.colors.greenDark : p.theme.colors.secondary)};
  }
  :hover ${TabTitle} {
    color: ${(p) => p.theme.colors.white};
  }
  :active ${TabTitle} {
    color: ${(p) => p.theme.colors.white};
  }
  :hover ${TabDate} {
    color: ${(p) => p.theme.colors.white};
  }
  :active ${TabDate} {
    color: ${(p) => p.theme.colors.white};
  }
  ${TabTitle} {
    color: ${(p) => (p.selected ? p.theme.colors.white : p.theme.colors.grey3)};
  }
  ${TabDate} {
    color: ${(p) => (p.selected ? p.theme.colors.white : p.theme.colors.grey3)};
  }
`;

const Divider = styled.div`
  width: ${(p) => p.theme.space[2]};
`;

const VertDivider = styled.div`
  height: ${(p) => p.theme.space[2]};
`;

type Props = {
  notes: INote[];
  addNote(): void;
  updateNote(note: INote): void;
  deleteNote(id: string): void;
  loading: boolean;
};

export const Notes: React.FC<Props> = ({ notes, addNote, updateNote, deleteNote, loading }) => {
  const [selectedNote, setSelectedNote] = useState<INote | undefined>(notes.length > 0 ? notes[0] : undefined);
  const [pendingChanges, setPendingChanges] = useState(false);

  const selectNote = async (note: INote) => {
    if (selectedNote && note.id === selectedNote.id) {
    } else {
      let confirm = true;
      if (pendingChanges) {
        confirm = await deleteModal('You have unsaved changes, proceeding will remove them. Continue?');
      }
      if (confirm) {
        setSelectedNote(note);
        setPendingChanges(false);
      }
    }
  };

  const save = (note: INote) => {
    updateNote(note);
    setPendingChanges(false);
  };

  const prevCount = useRef(notes.length);
  useEffect(() => {
    if (notes.length === 0) {
      setSelectedNote(undefined);
      prevCount.current = 0;
    } else if (prevCount.current !== notes.length) {
      setSelectedNote(notes[0]);
      prevCount.current = notes.length;
    }
  }, [notes]);

  return (
    <Container
      loading={loading}
      sx={{
        border: '1px solid',
        borderColor: 'grey2',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: 'main',
      }}
    >
      <Flex bg={'primary'} height={50} alignItems="center" px={[3]}>
        <TitleText>Notes</TitleText>
        <Box flex={1} />
        <Icon tooltip={'Add Note'} style={'add'} onClick={addNote} />
      </Flex>
      <LoadingBar loading={loading} height={'5px'} />
      <Flex>
        <Left>
          {notes.map((note, index) => (
            <Tab
              key={`${note}${index}`}
              selected={selectedNote !== undefined && note.id === selectedNote.id}
              onClick={() => selectNote(note)}
              p={[2]}
            >
              <TabTitle>{note.title}</TabTitle>
              <TabDate>{moment.unix(note.date).format('DD-MM-YY')}</TabDate>
            </Tab>
          ))}
        </Left>
        {selectedNote ? (
          <NoteForm
            note={selectedNote}
            deleteNote={deleteNote}
            onSave={save}
            onChange={() => setPendingChanges(true)}
          />
        ) : (
          <Flex justifyContent="center" alignItems="center" flex={1}>
            <Button onClick={addNote}>Add Note</Button>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
