import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, Text, Button } from 'rebass/styled-components';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import Home from './home';
import styled from 'styled-components';
import useAuth from 'hooks/useAuth';
import { Spinner } from 'components/spinner';
import AnimatedLogo from 'components/animatedLogo';
import { Input } from '@rebass/forms/styled-components';
import { variant } from 'util/constants';

const Container = styled(Flex)`
  background-image: linear-gradient(${(p) => p.theme.colors.primary}, ${(p) => p.theme.colors.primaryDark});
`;

const Login = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const { signIn } = useAuth();

  const [wrongEorP, setWrongEorP] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    const signedIn = await signIn(email, password);
    if (!signedIn) {
      setWrongEorP(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(push('/'));
    }
  }, [user]);

  if (user) return <Home />;

  return (
    <Container height={'100vh'} justifyContent={'center'} p={[3]}>
      <Flex justifyContent={'center'} alignItems={'center'} flex={1}>
        <Flex width={1 / 1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <Box maxWidth={200} mb={[4]}>
            {loading ? <Spinner /> : <AnimatedLogo pulseDelay={3000} />}
          </Box>
          <Box as="form" width={1 / 1} onSubmit={handleSubmit} maxWidth={300}>
            <Input
              name={'email'}
              placeholder={'Enter email address'}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required={true}
              type={'email'}
              variant={variant.forms.inputOnPrimary}
            />
            <Box mb={[2]} />
            <Input
              name={'password'}
              placeholder={'Enter password'}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              type={'password'}
              required={true}
              variant={variant.forms.inputOnPrimary}
            />
            <Box mb={[2]} />
            <Flex justifyContent={'flex-end'}>
              {wrongEorP && (
                <Text flex={1} alignSelf={'center'} variant={'error'}>
                  Incorrect email or password
                </Text>
              )}
              <Button as="button" type="submit" variant={variant.button.outlineOnWhite}>
                Log In
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Login;
