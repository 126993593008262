import React, { FC, useState } from 'react';
import { Flex, Button, Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { CoboxIcon } from './coboxIcon';
import useAuth from 'hooks/useAuth';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { push } from 'connected-react-router';
import { variant } from 'util/constants';
import { DropDownMenu } from './dropDownMenu';
import { DropdownItemsType } from 'util/types';

const Container = styled(Flex)`
  background-image: linear-gradient(90deg, ${(p) => p.theme.colors.primary}, ${(p) => p.theme.colors.primaryDark});
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

type Props = {};

const dropdownItems = [
  { label: 'Database', value: 'home' },
  { label: 'Admin Dashboard', value: 'admin' },
];

const Header: FC<Props> = () => {
  const dispatch = useDispatch();
  const { signOut, isAdmin } = useAuth();
  const { user } = useSelector((state: AppState) => state.auth);
  const [selectedOption, setSelectedOption] = useState<DropdownItemsType>(dropdownItems[0]);

  const getSelected = (): DropdownItemsType => {
    return selectedOption;
  };

  const sortSelect = (item: DropdownItemsType) => {
    dispatch(push(`/${item.value}`));
    setSelectedOption(item); // Add this line to update the selected option
  };

  return (
    <>
      <Container height={50} bg={'primary'} alignItems={'center'} px={[2]}>
        <CoboxIcon onClick={() => dispatch(push('/'))} />
        <Box flex={1} />
        <Text color={'white'} mr={[3]} fontSize={[1]} sx={{ userSelect: 'none' }}>
          {user ? user.email : ''}
        </Text>
        {isAdmin && (
          <DropDownMenu
            selected={getSelected()}
            menuItems={dropdownItems}
            onSelect={sortSelect}
            width="200px"
            style="white"
          />
        )}
        <Box mr={[2]} />
        <Button variant={variant.button.outlineOnWhite} onClick={signOut}>
          Log out
        </Button>
      </Container>
      <Box sx={{ position: 'fixed', top: 0, transform: 'translateY(50px)' }} width={1 / 1} height={8} bg={'offWhite'} />
    </>
  );
};

export default Header;
