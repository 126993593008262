import React, { useEffect, useState } from 'react';
import { Box, Flex, Button, Text, Card } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';
import styled from 'styled-components';
import { colors } from '../../util/theme';
import CreateUser from './CreateUser';
import { useFirestore } from 'react-redux-firebase';
import { firestoreCollection } from 'util/constants';
import { DefaultsType } from './types';
import useDefaultFieldsListener from 'hooks/useDefaultFieldsListener';

const FormInput = styled(Input)`
  border-bottom: 1px solid ${colors.grey2};
`;

const CustomButton = styled(Button)`
  :disabled {
    background-color: ${colors.grey2};
    cursor: not-allowed;
  }
`;

const Admin = () => {
  const [projectDefaults, setProjectDefaults] = useState<DefaultsType>({
    title: '',
    projectNumber: '',
    client: '',
    agency: '',
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const { defaults } = useDefaultFieldsListener();

  const firestore = useFirestore();

  const handleDefaultsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name) {
      setProjectDefaults((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (defaults) {
      setProjectDefaults(defaults);
    }
  }, [defaults]);

  const updateDefaultFields = async () => {
    try {
      const collectionRef = firestore.collection(firestoreCollection.projectDefaults);

      // Retrieve the document snapshot
      const snapshot = await collectionRef.limit(1).get();

      if (!snapshot.empty) {
        // Get the document ID
        const docId = snapshot.docs[0].id;

        // Update the document
        await collectionRef.doc(docId).update(projectDefaults);
        console.log('Document successfully updated!');
      } else {
        console.log('No document found in the collection.');
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  useEffect(() => {
    let isError = false;
    (Object.keys(projectDefaults) as (keyof DefaultsType)[]).forEach((item) => {
      if (!projectDefaults[item]) {
        isError = true;
        return;
      }
    });
    setIsEmpty(isError);
  }, [projectDefaults]);

  return (
    <Box p={[2]}>
      {/* Section to add users and passwords */}
      <CreateUser />
      {/* Section to backup data to the database */}
      <Box mb={[4]}>
        <Text fontSize={18} fontWeight="bold" mb={[2]}>
          Backup Data
        </Text>
        <Text fontSize={16} mb={[2]}>
          Click to backup all your data
        </Text>
        <Button>Backup</Button>
      </Box>

      {/* Section similar to ProjectInfo for managing projects Default tags */}
      <Card p={3}>
        <Flex flexDirection={['column']} style={{ gap: '16px' }}>
          <Flex alignItems="center" style={{ gap: '20px' }}>
            <FormInput
              style={{ width: '50%' }}
              onChange={handleDefaultsChange}
              name="title"
              value={projectDefaults.title}
              type="text"
            />
            <CustomButton onClick={updateDefaultFields} disabled={isEmpty} style={{ width: '20%' }}>
              Change value
            </CustomButton>
          </Flex>
          <Flex alignItems="center" style={{ gap: '20px' }}>
            <FormInput
              style={{ width: '50%' }}
              onChange={handleDefaultsChange}
              name="projectNumber"
              value={projectDefaults.projectNumber}
              type="text"
            />
            <CustomButton onClick={updateDefaultFields} disabled={isEmpty} style={{ width: '20%' }}>
              Change value
            </CustomButton>
          </Flex>
          <Flex alignItems="center" style={{ gap: '20px' }}>
            <FormInput
              style={{ width: '50%' }}
              onChange={handleDefaultsChange}
              name="client"
              value={projectDefaults.client}
              type="text"
            />
            <CustomButton onClick={updateDefaultFields} disabled={isEmpty} style={{ width: '20%' }}>
              Change value
            </CustomButton>
          </Flex>
          <Flex alignItems="center" style={{ gap: '20px' }}>
            <FormInput
              style={{ width: '50%' }}
              onChange={handleDefaultsChange}
              name="agency"
              value={projectDefaults.agency}
              type="text"
            />
            <CustomButton onClick={updateDefaultFields} disabled={isEmpty} style={{ width: '20%' }}>
              Change value
            </CustomButton>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export default Admin;
