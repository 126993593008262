import React from 'react';
import styled from 'styled-components';

const Polygon = styled.polygon`
  fill: none;
  stroke-miterlimit: 10;
  fill-rule: evenodd;
`;

type Props = {};

export const Up: React.FC<Props> = () => {
  return (
    <g>
      <Polygon points="38.5 31.8 25 18.3 11.5 31.8 38.5 31.8" />
    </g>
  );
};
