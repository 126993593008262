import React, { FC, useState, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import Upload from '../Upload';
import firebase from 'firebase';
import { LoadingBar } from '../loadingBar';
import uniqid from 'uniqid';
import useGallery from 'hooks/useGallery';
import GalleryDropzone from './galleryDropzone';
import imageCompression from 'browser-image-compression';
import { Input } from '@rebass/forms/styled-components';
import { variant } from 'util/constants';

const ImageInput = styled(Input)`
  text-align: center;
`;

const Gallery = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px;
  padding-bottom: 20px;
`;

const Thumbnail = styled.div`
  width: 200px;
  aspect-ratio: 1;
  padding: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ThumbnailImage = styled.img`
  width: 90%;
  height: 80%;
  display: block;
  border: 1px solid black;
`;

const GalleryContainer: FC<{
  projectID: string;
  files: IAsset[];
  isImagesLoading: boolean;
  isUploading(state: boolean): void;
}> = ({ projectID, files, isImagesLoading }) => {
  const [uploading, setUploading] = useState(false);
  const { uploadImages, updateImages } = useGallery();
  const [uploads, _setUploads] = useState<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>([]);
  const uploadsRef = React.useRef<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>(uploads);
  const setUploads = (uploads: { task: firebase.storage.UploadTask; id: string; fileName: string }[]) => {
    uploadsRef.current = uploads;
    _setUploads(uploads);
  };

  const [imageIds, setImageIds] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const initialImageIds = files.reduce((acc, image) => {
      acc[image.imageId!] = image.id;
      return acc;
    }, {} as { [key: string]: string });

    setImageIds(initialImageIds);
  }, [files, uploading]);

  const uploadMultipleFiles = async (file: File) => {
    setUploading(true);
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      uploadImages(compressedFile, projectID, (task) => {
        setUploads([...uploadsRef.current, { task: task, id: uniqid(), fileName: compressedFile.name }]);
      }).then((res) => {
        if (res) {
          setImageIds((prevState) => ({
            ...prevState,
            [res.toString()!]: compressedFile.name,
          }));
        }
      });
    } catch (error) {
      console.error('Error compressing file:', error);
    }
  };

  const handleInputChange = (id: string, value: string) => {
    setImageIds((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const updateImageName = (imageId: string, updatedName: string) => {
    updateImages(imageId, { id: updatedName });
  };

  return (
    <>
      <Box bg={'white'} width={1} height={'100%'}>
        <LoadingBar loading={uploading || isImagesLoading} />
        <Flex height={'100%'} flexDirection="column" style={{ flexGrow: 1 }}>
          {uploads.map((upload, index) => (
            <Upload key={`video${index}`} upload={upload.task} fileName={upload.fileName} uploading={setUploading} />
          ))}
          <GalleryDropzone upload={uploadMultipleFiles}>
            <Gallery>
              {files.map((image, index) => (
                <React.Fragment key={`${image.id}+${index}`}>
                  <Thumbnail>
                    {image?.downloadURL && <ThumbnailImage src={image.downloadURL} alt={image.id} />}
                    <ImageInput
                      value={imageIds[image.imageId!] || ''}
                      type="text"
                      placeholder="Enter name"
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateImageName(image.imageId!, e.target.value)
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(image.imageId!, e.target.value)
                      }
                      variant={variant.forms.inputFieldNoLine}
                    />{' '}
                  </Thumbnail>
                </React.Fragment>
              ))}
            </Gallery>
          </GalleryDropzone>
        </Flex>
      </Box>
    </>
  );
};

export default GalleryContainer;
