import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box } from 'rebass/styled-components';

const Animation = keyframes`
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  49% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
`;

const Container = styled(Box) <{ loading: boolean }>`
  width: 100%;
  background-color: ${(p) => (p.loading ? p.theme.colors.primaryDark : p.theme.colors.secondary)};
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

const Loading = styled(Box)`
  width: 100%;
  background-color: ${(p) => p.theme.colors.secondary};
  box-sizing: border-box;
  position: absolute;
  animation: ${Animation} 3s infinite;
`;

type Props = {
  loading: boolean;
  height?: string;
};

export const LoadingBar: React.FC<Props> = ({ loading, height = '5px' }) => {
  return (
    <Container loading={loading} style={{ height: height }}>
      {loading && <Loading style={{ height: height }} />}
    </Container>
  );
};
