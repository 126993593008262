import React, { FC, useState, useRef, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import useVideo from 'hooks/useVideo';
import Upload from '../Upload';
import VideoDropzone from './videoDropzone';
import firebase from 'firebase';
import { LoadingBar } from '../loadingBar';
import { VideoComponent } from './video';
import uniqid from 'uniqid';

const Bar = styled(Box)`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p) => p.theme.space[3]}px;
  box-sizing: border-box;
`;

const TitleText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const VideoContainer: FC<{
  record: IRecord;
  files: IVideo[];
  isUploading(state: boolean): void;
}> = ({ record, files, isUploading }) => {
  const [uploading, setUploading] = useState(false);
  const { uploadVideo } = useVideo();
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);
  const [uploads, _setUploads] = useState<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>([]);
  const uploadsRef = React.useRef<{ task: firebase.storage.UploadTask; id: string; fileName: string }[]>(uploads);
  const setUploads = (uploads: { task: firebase.storage.UploadTask; id: string; fileName: string }[]) => {
    uploadsRef.current = uploads;
    _setUploads(uploads);
  };

  useEffect(() => {
    isUploading(uploading);
  }, [uploading]);

  const uploadSingleFile = (file: File) => {
    setUploading(true);
    uploadVideo(file, record.id, (task) => {
      setUploads([...uploadsRef.current, { task: task, id: uniqid(), fileName: file.name }]);
    });
  };

  return (
    <>
      <Box bg={'white'} width={1} flex={1} sx={{ overflowY: 'auto' }}>
        <Flex height={'100%'} flexDirection="column">
          <LoadingBar loading={uploading} />
          <VideoDropzone upload={uploadSingleFile} height={'100%'} disabled={dropzoneDisabled}>
            {files.map((file, index) => (
              <VideoComponent
                key={`video${file.id}${index}`}
                video={file}
                recordID={record.id}
                isMastersOpen={(state) => setDropzoneDisabled(state)}
              />
            ))}
            {!dropzoneDisabled &&
              uploads.map((upload, index) => (
                <Upload
                  key={`video${index}`}
                  upload={upload.task}
                  fileName={upload.fileName}
                  uploading={setUploading}
                />
              ))}
          </VideoDropzone>
        </Flex>
      </Box>
    </>
  );
};

export default VideoContainer;
