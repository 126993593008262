import React, { FC, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import lottie, { AnimationItem } from 'lottie-web';

import LogoPulsePath from '../animations/cobox-white-pulse.json';

const SVGContainer = styled.svg`
    height: 100%;
    width: 100%;
`;

type Props = {
    pulseDelay: number;
};

const AnimatedLogo: FC<Props> = ({ pulseDelay }) => {
    const ContainerRef = useRef(null);
    const [animation, setAnimation] = useState<AnimationItem | undefined>(undefined);
    const [pulseTrigger, setPulseTrigger] = useState(false);
    const [timeOut, timeoutSet] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    }, [timeOut]);

    useEffect(() => {
        const InitialAnimation = lottie.loadAnimation({
            container: ContainerRef.current!,
            animationData: LogoPulsePath,
            loop: false,
            autoplay: true,
            renderer: 'svg',
        });
        setAnimation(InitialAnimation);
        let TO: any = setTimeout(() => {
            setPulseTrigger(true);
        }, pulseDelay);
        timeoutSet(TO);
    }, [pulseDelay]);

    useEffect(() => {
        if (pulseTrigger) {
            setPulseTrigger(false);
            if (animation) {
                animation.destroy();
            }
            const newAnimation = lottie.loadAnimation({
                container: ContainerRef.current!,
                animationData: LogoPulsePath,
                loop: false,
                autoplay: true,
                renderer: 'svg',
            });
            setAnimation(newAnimation);
            let TO: any = setTimeout(() => {
                setPulseTrigger(true);
            }, pulseDelay);
            timeoutSet(TO);
        }
    }, [pulseTrigger, pulseDelay, animation]);
    return (
        <SVGContainer viewBox={'0 0 1100 550'}>
            <g ref={ContainerRef} />
        </SVGContainer>
    );
};

export default AnimatedLogo;
