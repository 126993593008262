import React, { useEffect, useState, FC } from 'react';
import { Box, Flex, Text, Button } from 'rebass/styled-components';
import FileDropZone from 'components/fileDropzone';
import useProject from 'hooks/useProject';
import { Spinner } from 'components/spinner';
import { Input } from '@rebass/forms/styled-components';
import { variant, docType, firestoreCollection } from 'util/constants';
import moment from 'moment';
import styled from 'styled-components';
import { Icon, IconColour } from 'components/icon';
import { deleteDocModal } from 'util/modal';
import InputField from './input';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import useVideo from 'hooks/useVideo';
import firebase from 'firebase';
import { getImage } from 'util/index';
import useFeature from 'hooks/useFeature';
import DateSelector from './input/dateSelector';
import useDefaultFieldsListener from 'hooks/useDefaultFieldsListener';

const Bar = styled(Box)<{ isComplete: boolean }>`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`;

const KeyInput = styled(Input)`
  padding: 0;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
`;

type Props = {
  project: IProject;
};

const ProjectInfo: FC<Props> = ({ project }) => {
  const dispatch = useDispatch();
  const { updateProject, removeProject } = useProject(project.id);

  const [title, setTitle] = useState(project.title);
  const [jobNumber, setJobNumber] = useState(project.jobNumber);
  const [client, setClient] = useState(project.client);
  const [budget, setBudget] = useState(project.budget);
  const [date, setDate] = useState(project.date);
  const [tags, setTags] = useState(project.tags);
  const [agency, setAgency] = useState(project.agency);
  const [customeField1, setCustomField1] = useState<ICustomField>(
    project.customField1
      ? project.customField1
      : {
          key: 'Director',
          value: '',
        },
  );
  const [customeField2, setCustomField2] = useState<ICustomField>(
    project.customField2
      ? project.customField2
      : {
          key: 'Producer',
          value: '',
        },
  );
  const [customeField3, setCustomField3] = useState<ICustomField>(
    project.customField3
      ? project.customField3
      : {
          key: 'DOP',
          value: '',
        },
  );
  const [complete, setComplete] = useState(project.complete);
  const { uploadFeature } = useFeature(project.id);
  const { defaults } = useDefaultFieldsListener();

  const defaultTags = [
    'film',
    'commercial',
    'corporate',
    'social',
    'podcast',
    'animation',
    'audio',
    'digital',
    'music',
    'video',
  ];

  const addTag = (tag: string) => {
    const isUnique = tags.includes(tag);
    if (!isUnique && tag !== '') {
      setTags((current) => [...current, tag]);
    }
  };

  const deleteTag = (index: number) => {
    const newTags = [...tags.slice(0, index), ...tags.slice(index + 1, tags.length)];
    setTags(newTags);
  };

  useEffect(() => {
    updateProject(project.id, { ...project, tags: tags });
  }, [tags]);

  const deleteProject = async () => {
    await removeProject(project.id);
  };

  const [imageLoading, setImageLoading] = useState(false);

  const upload = async (file: File) => {
    uploadFeature(file, (task) => {
      task.on(
        'state_changed',
        (snapshot) => {
          setImageLoading(true);
        },
        (error) => {
          setImageLoading(false);
        },
        () => {
          setImageLoading(false);
        },
      );
    });
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: [2],
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        position: 'relative',
      }}
    >
      <Box
        bg={'white'}
        width={'100%'}
        sx={{
          border: '1px solid',
          borderColor: 'grey2',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: 'main',
        }}
      >
        <Bar isComplete={project.complete} p={[1]} bg={project.complete ? 'green' : 'primary'}>
          <Button variant={variant.button.outlineOnWhite} onClick={() => dispatch(push('/'))}>
            {`< Return to projects`}
          </Button>
          <Box flex={1} />
          <Text color={'white'} mr={[2]}>
            {project.complete ? 'Complete' : 'Incomplete'}
          </Text>
          <Icon
            tooltip={project.complete ? 'Set Incomplete' : 'Set Complete'}
            style={project.complete ? 'cross' : 'tick'}
            onClick={() => updateProject(project.id, { complete: !project.complete })}
          />
          <Box mr={[2]} />
          <Icon tooltip={'Delete Project'} style={'delete'} onClick={deleteProject} />
          <Box mr={[2]} />
        </Bar>
        <Box p={[3]}>
          <Flex mb={[3]}>
            <Box flex={1} mr={[4]}>
              <Text color="grey2">{defaults?.title ?? 'title'}</Text>
              <Input
                value={title}
                type="text"
                placeholder="Add project title..."
                onChange={(input: any) => setTitle(input.target.value)}
                onBlur={(input: any) => updateProject(project.id, { ...project, title: input.target.value })}
                variant={variant.forms.inputField}
              />
            </Box>
            <Box flex={1}>
              <Text color="grey2">{defaults?.projectNumber ?? 'Project Number'}</Text>
              <Input
                value={jobNumber}
                type="text"
                placeholder="Add project number..."
                onChange={(input: any) => setJobNumber(input.target.value)}
                onBlur={(input: any) => updateProject(project.id, { ...project, jobNumber: input.target.value })}
                variant={variant.forms.inputField}
              />
            </Box>
          </Flex>
          <Flex mb={[3]}>
            <Box flex={1}>
              <Text color="grey2">{defaults?.client ?? 'Client'}</Text>
              <Input
                value={client}
                type="text"
                placeholder="Add client..."
                onChange={(input: any) => setClient(input.target.value)}
                onBlur={(input: any) => updateProject(project.id, { ...project, client: input.target.value })}
                variant={variant.forms.inputField}
              />
            </Box>
            <Box mr={[4]} />
            <Box flex={1}>
              <Text color="grey2">{defaults?.agency ?? 'Agency'}</Text>
              <Input
                value={agency}
                type="text"
                placeholder="Add agency..."
                onChange={(input: any) => setAgency(input.target.value)}
                onBlur={(input: any) => updateProject(project.id, { ...project, agency: input.target.value })}
                variant={variant.forms.inputField}
              />
            </Box>
          </Flex>
          <Flex alignItems="center" mb={[3]}>
            <Box flex={1}>
              <Text color="grey2">Budget</Text>

              <Flex>
                <Text mr={[1]} color="grey3">
                  $
                </Text>
                <Input
                  value={budget}
                  step=".01"
                  type="number"
                  placeholder="Budget"
                  onChange={(input: any) => setBudget(input.target.value)}
                  onBlur={(input: any) => updateProject(project.id, { ...project, budget: input.target.value })}
                  variant={variant.forms.inputField}
                  height={30}
                />
              </Flex>
            </Box>
            <Box mr={[4]} />
            <Box flex={1}>
              <Text color="grey2">Date</Text>
              <DateSelector
                initialDate={moment.unix(date).toDate()}
                updated={(value) => updateProject(project.id, { ...project, date: moment(value).unix() })}
              />
            </Box>
          </Flex>
          <Flex alignItems="center" mb={[3]}>
            <Box flex={1}>
              <KeyInput
                color="grey2"
                value={customeField1.key}
                type="text"
                placeholder={customeField1.key}
                onChange={(input: any) => {
                  const inputValue = input.target.value;
                  setCustomField1((data) => ({ ...data, key: inputValue }));
                }}
                onBlur={(input: any) => updateProject(project.id, { ...project, customField1: customeField1 })}
              />

              <Flex>
                <Input
                  value={customeField1.value}
                  type="text"
                  placeholder={'Add ' + customeField1.key}
                  onChange={(input: any) => {
                    const inputValue = input.target.value;
                    setCustomField1((data) => ({ ...data, value: inputValue }));
                  }}
                  onBlur={(input: any) => updateProject(project.id, { ...project, customField1: customeField1 })}
                  variant={variant.forms.inputField}
                  height={30}
                />
              </Flex>
            </Box>
            <Box mr={[4]} />
            <Box flex={1}>
              <KeyInput
                color="grey2"
                value={customeField2.key}
                type="text"
                placeholder={customeField2.key}
                onChange={(input: any) => {
                  const inputValue = input.target.value;
                  setCustomField2((data) => ({ ...data, key: inputValue }));
                }}
                onBlur={(input: any) => updateProject(project.id, { ...project, customField2: customeField2 })}
              />
              <Flex>
                <Input
                  value={customeField2.value}
                  type="text"
                  placeholder={'Add ' + customeField2.key}
                  onChange={(input: any) => {
                    const inputValue = input.target.value;
                    setCustomField2((data) => ({ ...data, value: inputValue }));
                  }}
                  onBlur={(input: any) => updateProject(project.id, { ...project, customField2: customeField2 })}
                  variant={variant.forms.inputField}
                  height={30}
                />
              </Flex>
            </Box>
            <Box mr={[4]} />
            <Box flex={1}>
              <KeyInput
                color="grey2"
                value={customeField3.key}
                type="text"
                placeholder={customeField3.key}
                onChange={(input: any) => {
                  const inputValue = input.target.value;
                  setCustomField3((data) => ({ ...data, key: inputValue }));
                }}
                onBlur={(input: any) => updateProject(project.id, { ...project, customField3: customeField3 })}
              />

              <Flex>
                <Input
                  value={customeField3.value}
                  type="text"
                  placeholder={'Add ' + customeField3.key}
                  onChange={(input: any) => {
                    const inputValue = input.target.value;
                    setCustomField3((data) => ({ ...data, value: inputValue }));
                  }}
                  onBlur={(input: any) => updateProject(project.id, { ...project, customField3: customeField3 })}
                  variant={variant.forms.inputField}
                  height={30}
                />
              </Flex>
            </Box>
          </Flex>
          <Box />
          <Flex flex={1} flexWrap={'wrap'} alignItems="center">
            <Box my={[1]} mr={[2]} maxWidth={200}>
              <InputField
                initialInput={''}
                type="text"
                placeholder="Add tag"
                onChange={addTag}
                fieldName=""
                onEnter
                tooltip="add tag"
              />
            </Box>
            {defaultTags.map((tag, index) => (
              <>
                {!tags.includes(tag) && (
                  <Flex
                    key={tag}
                    height={30}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        bg: 'grey2',
                      },
                    }}
                    my={[1]}
                    mr={[2]}
                    alignItems="center"
                    p={[2]}
                    bg="grey3"
                    onClick={() => addTag(tag)}
                  >
                    <Text color="white">+ {tag}</Text>
                  </Flex>
                )}
              </>
            ))}
            {tags.map((tag, index) => (
              <Flex
                key={tag}
                height={30}
                sx={{ border: '0.5px solid', borderColor: 'grey2' }}
                my={[1]}
                mr={[2]}
                alignItems="center"
                p={[2]}
              >
                <Text color="grey3">{tag}</Text>
                <Box width={10} />
                <Icon
                  style={'cross'}
                  onClick={() => deleteTag(index)}
                  noBox
                  colour={IconColour.grey}
                  tooltip={`delete ${tag}?`}
                >
                  x
                </Icon>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Box>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'grey2',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: 'main',
        }}
      >
        <FileDropZone upload={upload} allowClick={true}>
          {imageLoading && (
            <Flex
              sx={{ position: 'absolute', width: '100%', height: '100%' }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Spinner />
            </Flex>
          )}
          <Box
            opacity={imageLoading ? 0.25 : 1}
            height={'100%'}
            width={'100%'}
            minHeight={300}
            sx={{
              ':hover': { filter: 'brightness(1.1)' },
              ':active': { filter: 'brightness(0.8)' },
              backgroundImage: `url('${project.featureURL}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </FileDropZone>
      </Box>
    </Box>
  );
};

export default ProjectInfo;
