import { useEffect } from 'react';
import firebase from 'firebase/app';
import { placeholderProject } from 'util/placeholder';
import { firestoreCollection } from 'util/constants';
import { useDispatch } from 'react-redux';
import { setProject } from 'store/dataSlice';

const useProjectListener = (id: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection(firestoreCollection.projects)
      .doc(id)
      .onSnapshot((snapshot) => {
        const project = snapshot.data() as IProject;
        if (project) {
          dispatch(setProject(project));
        } else {
          dispatch(setProject(placeholderProject));
        }
      });
    return () => {
      unsubscribe();
    };
  }, []);
};

export default useProjectListener;
