import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, IconColour } from '../icon';
import moment from 'moment';
import { DropDownMenu } from '../dropDownMenu';
import path from 'path';
import prettyBytes from 'pretty-bytes';
import axios from 'axios';
import { VideoPlayer } from './videoPlayer';
import { Text, Box, Button, Flex } from 'rebass/styled-components';
import useVideo from 'hooks/useVideo';
import Modal from '../modal';
import firebase from 'firebase/app';
import { deleteModal } from 'util/modal';
import { ExclamationIcon } from '../exclamationIcon';
import { Input } from '@rebass/forms';
import Theme from 'util/theme';
import ProjectModal from 'components/asset/projectsModal';
import useRecords from 'hooks/useRecords';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import MovingModal from 'components/asset/movingModal';
import { DropdownItemsType } from 'util/types';

const InputContainer = styled(Box)<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? '150px' : p.width)};
  box-sizing: border-box;
`;

const WarningInputContainer = styled(Box)<{ width: string }>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? '150px' : p.width)};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const WarningMessage = styled(Text)`
  font-size: ${Theme.fontSizes[1]}px;
  background-color: #ffe74b;
  color: #333;
  width: fit-content;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 20px;
`;

const NotesInput = styled(Input)`
  border: none;
  outline: none;
  color: ${Theme.colors.grey3};
  ::placeholder {
    color: ${Theme.colors.grey3};
  }
`;

const Container = styled(Box)<{ isPreview: boolean }>`
  position: relative;
  :nth-child(odd) {
    background-color: ${(p) => p.theme.colors.white};
  }
  :nth-child(even) {
    background-color: ${(p) => p.theme.colors.grey1};
  }
`;

const VideoBar = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  position: relative;
`;

const LoadingBar = styled(Box)<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.green};
  transform-origin: left;
  transform: scaleX(${(p) => p.percent / 100});
  opacity: ${(p) => (p.percent === 0 || p.percent === 100 ? 0 : 0.2)};
  transition: transform 0.5s ease, opacity 1s ease;
`;

const VideoText = styled(Text)`
  color: ${(p) => p.theme.colors.grey3};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextComponent = styled.p`
  color: ${(p) => p.theme.colors.grey3};
  text-align: left;
  width: 100%;
  white-space: nowrap;
`;

const dropdownItems: DropdownItemsType[] = [
  { label: 'Preview', value: 'preview' },
  { label: 'Master', value: 'master' },
  { label: 'CAD', value: 'CAD' },
  { label: 'Distribution', value: 'distribution' },
];

type Props = {
  video: IVideo;
  recordID: string;
  isMastersOpen(state: boolean): void;
};

export const VideoComponent: React.FC<Props> = ({ video, recordID, isMastersOpen }) => {
  const [videoPlaying, setVideoPlaying] = useState<IVideo | undefined>(undefined);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [url, setUrl] = useState('');
  const [notes, setNotes] = useState(video.notes ? video.notes : '');
  const { deleteVideo, updateVideo, uploadVideo } = useVideo();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const { addRecord } = useRecords();
  const { records } = useSelector((state: AppState) => state.data);
  const [isMoving, setIsMoving] = useState(false);

  const currentRecord = records.find((item) => item.id === recordID);

  useEffect(() => {
    const getUrl = async () => {
      const newURL = await firebase.storage().refFromURL(video.objectRef).getDownloadURL();
      setUrl(newURL);
    };
    getUrl();
  }, []);

  const downloadVideo = async () => {
    if (video.category !== 'preview') {
      const confirm = await deleteModal(
        'This is an archived file, downloading will incur significant, monetary cost. Continue?',
      );
      if (!confirm) {
        return;
      }
    }

    const response = await axios.get(url, {
      responseType: 'blob',
      onDownloadProgress: (progress) => {
        const percent = (progress.loaded / progress.total) * 100;
        setDownloadProgress(percent);
      },
    });
    const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadURL;
    link.setAttribute('download', video.id);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const videoPlay = (video: IVideo) => {
    setVideoPlaying(video);
  };

  const updateSelected = (item: DropdownItemsType) => {
    updateVideo(video.id, { ...video, category: item.value as IVideo['category'] });
  };

  const handleSelectProject = (project: string) => {
    setSelectedProject(project);
    setIsModalOpen(false);
  };

  const moveAssetToProject = async (video: IVideo, projectTitle: string) => {
    try {
      setIsMoving(true); // Start showing the "Please wait" message
      const projectsRef = firebase.firestore().collection('projects');
      const querySnapshot = await projectsRef.where('title', '==', projectTitle).get();

      if (querySnapshot.empty) {
        console.error(`Project with title ${projectTitle} not found.`);
        setIsMoving(false); // Stop showing the "Please wait" message if there's an error
        return;
      }

      const projectDoc = querySnapshot.docs[0];
      const projectId = projectDoc.id;

      // Check if a record with the current record's title already exists in the selected project
      const recordsRef = firebase.firestore().collection('records');
      const recordSnapshot = await recordsRef
        .where('projectID', '==', projectId)
        .where('title', '==', currentRecord?.title)
        .get();

      let recordId;
      if (!recordSnapshot.empty) {
        const existingRecord = recordSnapshot.docs[0];
        recordId = existingRecord.id;
      } else {
        recordId = addRecord(projectId, currentRecord?.title);
      }

      // Get current asset document
      const videoRef = firebase.firestore().collection('videos').doc(video.id);

      // Upload the asset to the new project
      const url = await firebase.storage().refFromURL(video.objectRef).getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = path.basename(video.objectRef);
      const file = new File([blob], fileName);

      uploadVideo(file, recordId, (upload: firebase.storage.UploadTask) => {
        upload.on(
          'state_changed',
          (snapshot) => {
            // Handle upload progress if needed
          },
          (error) => {
            console.error('Error uploading asset:', error);
            setIsMoving(false); // Stop showing the "Please wait" message if there's an error
          },
          async () => {
            const downloadURL = await upload.snapshot.ref.getDownloadURL();
            await videoRef.update({
              project: firebase.firestore().doc(`projects/${projectId}`),
              url: downloadURL,
            });
            alert(`Video with ID ${video.id} moved to project with ID ${projectId}.`);
            setIsMoving(false); // Stop showing the "Please wait" message after the upload is complete
          },
        );
      });
    } catch (error) {
      console.error('Error moving video to project:', error);
      setIsMoving(false); // Stop showing the "Please wait" message if there's an error
    }
  };

  useEffect(() => {
    if (selectedProject) {
      moveAssetToProject(video, selectedProject); // Move asset when project selected
    }
  }, [selectedProject]);

  return (
    <>
      {videoPlaying && (
        <Modal>
          <VideoPlayer
            video={videoPlaying}
            exit={() => setVideoPlaying(undefined)}
            downloadVideo={downloadVideo}
            url={url}
          />
        </Modal>
      )}

      <Container isPreview={video.category === 'preview'}>
        <LoadingBar percent={downloadProgress} />
        <VideoBar px={[3]}>
          {video.category === 'preview' ? (
            <Icon tooltip={'Play Video'} style={'play'} onClick={() => videoPlay(video)} colour={IconColour.grey} />
          ) : (
            <ExclamationIcon />
          )}
          <Box mr={[3]} />
          <WarningInputContainer width={'100%'}>
            <VideoText>{path.basename(video.id, path.extname(video.id))}</VideoText>
            {video.category !== 'master' && <WarningMessage>Are your masters marked correctly?</WarningMessage>}
          </WarningInputContainer>
          <Box mr={[2]} />
          <InputContainer width={'240px'}>
            <NotesInput
              value={notes}
              placeholder={'Input Notes/Details'}
              type="text"
              onChange={(input: any) => setNotes(input.target.value)}
              onBlur={(input: any) => updateVideo(video.id, { notes: input.target.value })}
            />
          </InputContainer>
          <Box mr={[4]} />
          <InputContainer width={'70px'}>
            <TextComponent>{path.extname(video.id)}</TextComponent>
          </InputContainer>
          <Box mr={[2]} />
          <InputContainer width={'70px'}>
            <TextComponent>{prettyBytes(parseInt(video.size as any))}</TextComponent>
          </InputContainer>
          <Box mr={[2]} />
          <DropDownMenu
            width={'140px'}
            menuItems={dropdownItems}
            selected={dropdownItems.find((item) => item.value === video.category) || dropdownItems[0]}
            onSelect={updateSelected}
          />
          <Box mr={[3]} />
          <InputContainer width={'80px'}>
            <TextComponent>{moment.unix(video.date).format('DD-MM-YY')}</TextComponent>
          </InputContainer>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Icon style={'download'} onClick={downloadVideo} colour={IconColour.grey} />
            <Icon
              style={'delete'}
              onClick={() => deleteVideo(video.id, video.category === 'preview')}
              colour={IconColour.grey}
            />
            <Icon style={'move'} onClick={() => setIsModalOpen(true)} colour={IconColour.grey} />
          </div>
        </VideoBar>
      </Container>
      {isModalOpen && <ProjectModal onClose={() => setIsModalOpen(false)} onSelect={handleSelectProject} />}
      {isMoving && <MovingModal />}
    </>
  );
};
