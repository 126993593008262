import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Container = styled.input<{
  width: string;
  colourStyle: 'primary' | 'secondary';
  minHeight: boolean;
  noBorder: boolean;
}>`
  width: ${(p) => p.width};
  min-width: ${(p) => (p.width === '100%' ? 'auto' : p.width)};
  height: 100%;
  max-height: 40px;
  min-height: ${(p) => (p.minHeight ? 'auto' : '40px')};
  box-sizing: border-box;
  color: ${(p) => (p.colourStyle === 'primary' ? p.theme.colors.grey3 : p.theme.colors.white)};
  background-color: transparent;
  border: 0.5px solid
    ${(p) => (p.noBorder ? 'transparent' : p.colourStyle === 'primary' ? p.theme.colors.grey2 : p.theme.colors.white)};
  padding: ${(p) => (p.noBorder ? 0 : p.theme.space[2])}px;
  ::placeholder {
    color: ${(p) => (p.colourStyle === 'primary' ? p.theme.colors.grey2 : p.theme.colors.white)};
    opacity: 0.5;
  }
  :focus {
    outline: none;
    border: 0.5px solid ${(p) => (p.noBorder ? 'transparent' : p.theme.colors.secondary)};
    color: ${(p) =>
      p.noBorder
        ? p.colourStyle === 'primary'
          ? p.theme.colors.primary
          : p.theme.colors.white
        : p.colourStyle === 'primary'
        ? p.theme.colors.grey3
        : p.theme.colors.white};
  }
  font-family: 'Poppins', sans-serif;
  ::selection {
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
  }
`;

type Props = {
  value: string;
  name: string;
  placeholder?: string;
  continuousUpdate?: boolean;
  update(input: string): void;
  onEnterPress(input:string): void;
  width?: string;
  style?: 'primary' | 'secondary';
  noMinHeight?: boolean;
  noBorder?: boolean;
  type?: string;
  required?: boolean;
  isActive?(value: boolean): void;
  disabled?: boolean;
  setInput: Dispatch<SetStateAction<string>>
};

export const TextInput: React.FC<Props> = ({
  value,
  name,
  placeholder = 'Write here...',
  continuousUpdate = false,
  update,
  onEnterPress,
  width = '100%',
  style = 'primary',
  noMinHeight = false,
  noBorder = false,
  type = 'text',
  required = false,
  isActive,
  disabled = false,
  setInput
  
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    setInput(value);
  }, []);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const detectEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const ref = inputRef.current! as HTMLInputElement;
      ref.blur();
    }
  };

  useEffect(() => {
    if (continuousUpdate) {
      update(value);
    }
  }, [value]);

  const onBlur = () => {
    if (value !== value) {
      update(value);
      onEnterPress(value)
    }
    if (isActive) {
      isActive(false);
    }
  };

  return (
    <Container
      type={type}
      name={name}
      ref={inputRef}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyPress={detectEnter}
      width={width}
      colourStyle={style}
      minHeight={noMinHeight}
      onBlur={onBlur}
      noBorder={noBorder}
      required={required}
      onFocus={() => (isActive ? isActive(true) : () => {})}
      disabled={disabled}
    />
  );
};
