import buttonVariants from './variants/buttons';
import formVariants from './variants/forms';
import textVariants from './variants/text';
import cardVariants from './variants/card';
import boxVariants from './variants/box';

export const breakpoints = ['40em', '52em', '64em'];

export const fontSizes = [12, 14, 16, 20, 24, 32];

export const space = [0, 4, 8, 16, 32, 64, 128, 256];

export const colors = {
  primary: '#E5056A',
  primaryLight: '#EF258A',
  primaryDark: '#9E1269',
  secondary: '#F7C341',
  green: '#12B158',
  greenDark: '#009782',
  white: '#fff',
  offWhite: '#f3f3f3',
  grey1: '#F9F9F9',
  grey2: '#D5D5D5',
  grey3: '#8F8F8F',
  greyDark: '#424242',
};

export const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'inherit',
  monospace: 'Menlo, monospace',
};

export const fontWeights = [300, 500, 600];

export const lineHeights = {
  body: 1.5,
  heading: 1.25,
};

export const shadows = {
  main: '0 0 15px rgba(0, 0, 0, 0.15)',
};

const Theme = {
  breakpoints,
  fontSizes,
  space,
  colors,
  fonts,
  fontWeights,
  lineHeights,
  shadows,
  forms: { ...formVariants, largeInput: { color: 'red' } },
  variants: {
    ...cardVariants,
    ...boxVariants,
  },
  text: textVariants,
  buttons: buttonVariants,
};

export default Theme;
