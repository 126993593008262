import React, { useState, useRef, useEffect, FC } from 'react';
import styled from 'styled-components';
import { Text, Box, Flex, Button } from 'rebass/styled-components';
import { Icon } from '../icon';
import { LoadingBar } from '../loadingBar';
import GalleryContainer from './galleryContainer';
import useGalleryListner from 'hooks/useGalleryListner';
import useUtil from 'hooks/useUtil';

const Container = styled(Box)<{ loading: boolean }>`
  width: 100%;
  min-height: 400px;
  height: 409px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: ${(p) => (p.loading ? p.theme.colors.grey1 : p.theme.colors.white)};
`;

const TitleText = styled(Text)`
  color: ${(p) => p.theme.colors.white};
  user-select: none;
`;

const Left = styled(Box)`
  width: 200px;
  height: 350px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.grey1};
  overflow-y: auto;
`;

const Right = styled(Box)`
  flex-grow: 1;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ImageContainer = styled(Box)`
  width: 100px;
  height: 100px;
  margin: 5px;
  background-color: ${(p) => p.theme.colors.grey2};
  background-size: cover;
  background-position: center;
  position: relative;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${(p) => p.theme.colors.white};
  color: white;
  padding: 0;
`;

type Props = {
  loading: boolean;
  projectId: string;
};

export const Gallery: FC<Props> = ({ loading, projectId }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentSort } = useUtil();
  const { images, setImagesLoading, imagesLoading } = useGalleryListner(
    projectId,
    currentSort.sortType,
    currentSort.direction,
  );

  return (
    <Container
      loading={false}
      sx={{
        border: '1px solid',
        borderColor: 'grey2',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: 'main',
      }}
    >
      <Flex bg={'primary'} height={50} alignItems="center" px={[3]}>
        <TitleText>Gallery</TitleText>
        <Box flex={1} />
      </Flex>
      <LoadingBar loading={loading} height={'0px'} />
      <Box height={400} bg="offWhite">
        <GalleryContainer
          files={images}
          projectID={projectId}
          isUploading={setImagesLoading}
          isImagesLoading={imagesLoading}
        />
      </Box>
    </Container>
  );
};
